import { CommunicationOptions } from 'modules/dashboard/types';

export enum SupportRequestedType {
  saveProducts = 'saveProducts',
  updateWidgetSettings = 'updateWidgetSettings',
  updateWebhooks = 'updateWebhooks',
  saveFinancing = 'saveFinancing',
  saveProductConfigs = 'saveProductConfigs',
  saveQuoteSettings = 'saveQuoteSettings',
  saveClpButtonSettings = 'saveClpButtonSettings',
}

export interface SupportRequestedEmailBody {
  requestBody: string;
  errorMessage: string;
  type: SupportRequestedType;
}

export interface ContactEmailDetails {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  notes?: string;
  address: string | null;
  type: string;
  structureState?: number;
  marketSlug?: string;
  sessionId?: string;
  externalUrl?: string;
  communicationOptions: CommunicationOptions | null;
}

export const UPSERT_SMTP_FORM_FIELDS = {
  email: 'email',
  host: 'host',
  port: 'port',
  password: 'password',
  from: 'from',
  refreshToken: 'refreshToken',
  cc: 'cc',
} as const;

export interface UpsertSmtpForm {
  [UPSERT_SMTP_FORM_FIELDS.email]: string;
  [UPSERT_SMTP_FORM_FIELDS.cc]: string;
  [UPSERT_SMTP_FORM_FIELDS.host]: string;
  [UPSERT_SMTP_FORM_FIELDS.port]: string | number;
  [UPSERT_SMTP_FORM_FIELDS.password]: string | null;
  [UPSERT_SMTP_FORM_FIELDS.from]: string | null;
  [UPSERT_SMTP_FORM_FIELDS.refreshToken]: string | null;
}
