import {
  SubscriptionCountry,
  SubscriptionPlan,
  SubscriptionTypeGeneral,
} from 'modules/subscription/types';
import { FormDragAndDropValue } from 'components/Upload';

export const MapPlatformOption = {
  mapbox: 'Mapbox',
  googleMaps: 'GoogleMaps',
} as const;

export const RecurlyAccountOption = {
  existing: 'Existing',
  new: 'New',
} as const;

export enum SubscriptionFormKey {
  Type = 'subscriptionType',
  Country = 'subscriptionCountry',
  Plan = 'subscriptionPlan',
  StartDate = 'subscriptionStartDate',
  EndDate = 'subscriptionEndDate',
  Markets = 'markets',
  States = 'states',
}

export enum ContractorFormKey {
  // CompanyInfo
  CompanyName = 'companyName',
  HsCompanyId = 'hsCompanyId',
  Website = 'website',
  CompanyEmail = 'companyEmail',
  CompanyPhone = 'companyPhone',
  Domain = 'domain',
  Logo = 'logo',
  MapPlatform = 'mapPlatformOption',
  // Recurly Account
  RecurlyAccountType = 'recurlyAccountOption',
  RecurlyAccountId = 'recurlyAccountId',
  // Company Owner
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  Phone = 'phone',
  JobTitle = 'jobTitle',
}

export interface ContractorFormValues extends UpsertSubscriptionFormValues {
  // CompanyInfo
  [ContractorFormKey.CompanyName]: string;
  [ContractorFormKey.HsCompanyId]: string;
  [ContractorFormKey.Website]: string;
  [ContractorFormKey.CompanyEmail]: string;
  [ContractorFormKey.CompanyPhone]: string;
  [ContractorFormKey.Domain]: string;
  [ContractorFormKey.Logo]: string | FormDragAndDropValue;
  [ContractorFormKey.MapPlatform]: typeof MapPlatformOption[keyof typeof MapPlatformOption];
  // Recurly Account
  [ContractorFormKey.RecurlyAccountType]: typeof RecurlyAccountOption[keyof typeof RecurlyAccountOption];
  [ContractorFormKey.RecurlyAccountId]: string;
  // Company Owner
  [ContractorFormKey.FirstName]: string;
  [ContractorFormKey.LastName]: string;
  [ContractorFormKey.Email]: string;
  [ContractorFormKey.Phone]: string;
  [ContractorFormKey.JobTitle]: string;
}

export interface UpsertSubscriptionFormValues {
  [SubscriptionFormKey.Type]: SubscriptionTypeGeneral;
  [SubscriptionFormKey.Country]: SubscriptionCountry | null;
  [SubscriptionFormKey.Plan]: SubscriptionPlan | null;
  [SubscriptionFormKey.StartDate]: Date | null;
  [SubscriptionFormKey.EndDate]: Date | null;
  [SubscriptionFormKey.Markets]: string[];
  [SubscriptionFormKey.States]: (number | string)[];
}
