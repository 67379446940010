import mean from 'lodash/mean';

import { PriceConfig } from './priceConfig';
import { MonthlyPrice } from './monthlyPrice';

import {
  DefaultFinancingTypes,
  PriceInfo,
  RateSheet,
  ProductCategory,
} from 'modules/financing/types';
import * as constants from 'modules/financing/constants';
import { ProductMerchantFeeType } from 'modules/product/types';
import { MerchantFeeValues } from '../types';
import { toFixed } from 'utils';
import { formatMerchantFeeValue } from './utils';
import { validateMerchantFeeRange } from 'validators';

export class ContractorLoanPro {
  constructor({
    priceConfig,
    monthlyPrice,
  }: {
    priceConfig: PriceConfig;
    monthlyPrice: MonthlyPrice;
  }) {
    this.priceConfig = priceConfig;
    this.monthlyPriceInstance = monthlyPrice;
  }

  private readonly monthlyPriceInstance: MonthlyPrice;

  private readonly priceConfig: PriceConfig;

  get activeRateSheets(): RateSheet[] {
    return this.priceConfig.rateSheets.filter(item => item.isActive);
  }

  get offerCodeRateSheets(): RateSheet[] {
    let offerCodeRateSheets: RateSheet[] = [];

    const { product, offerCodes, rateSheets } = this.priceConfig;

    if (!product) {
      return offerCodeRateSheets;
    }

    if (product.loanProductId > 0 && offerCodes.length) {
      const productOfferCode = offerCodes.find(
        item => item.id === product.loanProductId && item.isActive,
      );

      if (productOfferCode) {
        offerCodeRateSheets = productOfferCode.rateSheetMerchantProductPrices.reduce(
          (acc: RateSheet[], item) => {
            const rateSheet = rateSheets.find(
              rateSheet => rateSheet.rateSheetMerchantProductPriceId === item.id,
            );
            return !rateSheet ? acc : [...acc, rateSheet];
          },
          [],
        );
      }
    }

    return offerCodeRateSheets;
  }

  get rateSheets(): RateSheet[] {
    return this.offerCodeRateSheets.length ? this.offerCodeRateSheets : this.activeRateSheets;
  }

  get merchantFeeValues(): MerchantFeeValues {
    const values = this.rateSheets.map(item => +item.merchantFee.replace('%', ''));

    return {
      [ProductMerchantFeeType.Min]: formatMerchantFeeValue(Math.min(...values)),
      [ProductMerchantFeeType.Average]: formatMerchantFeeValue(mean(values)),
      [ProductMerchantFeeType.Max]: formatMerchantFeeValue(Math.max(...values)),
    };
  }

  get productMerchantFee(): number {
    const { product } = this.priceConfig;

    if (!product) {
      return 1;
    }

    if (!this.offerCodeRateSheets.length && product.loanProductId > 0) {
      return this.merchantFeeValues[ProductMerchantFeeType.Average];
    }

    if (product.merchantFeeType !== ProductMerchantFeeType.Custom) {
      return this.merchantFeeValues[product.merchantFeeType];
    }

    return validateMerchantFeeRange(
      product.customMerchantFee,
      this.merchantFeeValues.Min,
      this.merchantFeeValues.Max,
    )
      ? product.customMerchantFee
      : this.merchantFeeValues.Average;
  }

  generateLoanInformation(priceInfo: PriceInfo): PriceInfo {
    const result: PriceInfo = {
      ...priceInfo,
    };

    let paymentFactorLow = Number.MAX_SAFE_INTEGER;

    const { product, selectQQFinancing, unavailableStates, centerpointState, financing } =
      this.priceConfig;

    if (!this.rateSheets.length || !product) {
      return result;
    }

    if (selectQQFinancing) {
      if (!(unavailableStates && centerpointState)) {
        return result;
      } else if (unavailableStates.includes(centerpointState)) {
        result.priceType = DefaultFinancingTypes.BasicFinancing;
        if (product.loanProductId !== constants.NoFinancing) {
          result.monthly = this.monthlyPriceInstance.calculateMonthlyPrice(result.total);
          result.months = financing.months;
          result.apr = financing.percentageRate;
        }
        return result;
      }
    }

    result.priceType = DefaultFinancingTypes.ContractorLoanPro;

    if (product.loanProductId === constants.NoFinancing) {
      return result;
    }

    this.rateSheets.forEach(rateSheet => {
      if (
        ([ProductCategory.fixedRate, ProductCategory.fixedPayment] as unknown[]).includes(
          rateSheet.productCategory,
        )
      ) {
        if (rateSheet.paymentFactorLow < paymentFactorLow) {
          paymentFactorLow = rateSheet.paymentFactorLow;
          result.paymentsTooltipMonths = rateSheet.fullTerm;
          result.paymentsTooltipInterest = rateSheet.apr;
        }
        const interest = +rateSheet.apr.slice(0, rateSheet.apr.indexOf('%'));

        if (result.interest === undefined || result.interest > interest) {
          result.interest = interest;
          result.interestTooltipMonths = rateSheet.fullTerm;
        }
      } else {
        // Temporary return supporting Same-As-Cash, because it breaks 0.00% APR block
        const months = [ProductCategory.deferredInterest, ProductCategory.sameAsCash].some(
          category => category === rateSheet.productCategory,
        )
          ? +rateSheet.shortDescription.slice(0, rateSheet.shortDescription.indexOf('m'))
          : +rateSheet.fullTerm;
        result.months = months > (result.months || 0) ? months : result.months;
      }
    });

    if (result.interest === undefined) {
      result.interest = 0;
    }

    if (product.includeMerchantFee) {
      result.merchantFee = this.productMerchantFee;
      result.merchantFeeValues = this.merchantFeeValues;
      result.merchantFeeAmount = +toFixed(result.total * this.productMerchantFee, 2);
      result.total = +toFixed(result.total + result.merchantFeeAmount, 2);
    }

    if (paymentFactorLow !== Number.MAX_SAFE_INTEGER) {
      result.payments = +toFixed(result.total * paymentFactorLow, 0);
      result.paymentFactorLow = paymentFactorLow;
    }

    return result;
  }
}
