import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';

import { I18nEnum } from 'types';
import { selectors as userSelectors } from 'modules/auth';
import { actions as modalActions, ModalTypes } from 'modules/modal';
import { actions as messageActions, MessageTypes } from 'modules/message';
import { useAppMutation } from 'modules/reactQuery/useAppQuery';
import { services, constants } from 'modules/developer';
import {
  Creds,
  IntegrationType,
  UseUpsertIntegrationProps,
  EagleviewConnection,
  JobNimbusIntegration,
  LeapIntegration,
  OneClickContractorIntegration,
} from 'modules/developer/types';
import {
  MarketsAccounts,
  services as ordersServices,
  queries as orderQueries,
} from 'modules/orders';
import { actions as marketActions } from 'modules/markets';

export const useUpsertBeaconConnection = (isUpdate?: boolean) => {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation<{ body: Creds; callback?: () => void }>({
    mutationFn: variables => services.upsertBeaconConnectionAPI(user.id, variables.body),
    onSuccess: async (_, variables) => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.success,
          intl.formatMessage({
            id: isUpdate
              ? I18nEnum.TheChangesHaveBeenSaved
              : I18nEnum.BeaconWasConnectedSuccessfully,
          }),
        ),
      );
      if (isUpdate) {
        dispatch(modalActions.closeModal());
      }

      queryClient.invalidateQueries({
        queryKey: [constants.BEACON_CONNECTION_KEY],
        exact: true,
      });
      await orderQueries.invalidateOrdersQueries();
      variables.callback && variables.callback();
    },
    onError: () => {
      if (isUpdate) {
        dispatch(
          messageActions.openMessage(
            MessageTypes.error,
            intl.formatMessage({ id: I18nEnum.UnfortunatelyTheChangesHaveNotBeenSaved }),
          ),
        );
      }
    },
  });
};

export const useDeleteBeaconConnection = () => {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation({
    mutationFn: () => services.deleteBeaconConnectionAPI(user.id),
    onSuccess: async () => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.success,
          intl.formatMessage({ id: I18nEnum.BeaconProPlusHasBeenDisconnected }),
        ),
      );
      dispatch(modalActions.closeModal());

      queryClient.invalidateQueries({
        queryKey: [constants.BEACON_CONNECTION_KEY],
        exact: true,
      });
      await orderQueries.invalidateOrdersQueries();
    },
    onError: () => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.error,
          intl.formatMessage({ id: I18nEnum.UnfortunatelyBeaconProPlusHasNotBeenDisconnected }),
        ),
      );
    },
  });
};

export const useAssignBeaconAccountsToMarkets = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation<{
    body: MarketsAccounts;
    marketsCount: number;
    assignedMarketsCount: number;
  }>({
    mutationFn: variables =>
      ordersServices.assignBeaconAccountsToMarketsAPI(user.id, variables.body),
    onSuccess: (_, variables) => {
      dispatch(
        modalActions.openModal(ModalTypes.beaconConnectionSuccess, {
          ...variables,
          fromScratch: true,
        }),
      );
      dispatch(marketActions.getUserMarkets());
    },
    onError: () => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.error,
          intl.formatMessage({ id: I18nEnum.UnfortunatelyTheChangesHaveNotBeenSaved }),
        ),
      );
    },
  });
};

export const useUpsertIntegration = ({
  successMessage,
  errorMessage,
}: {
  successMessage: string;
  errorMessage: string;
}) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation<Partial<IntegrationType>>({
    mutationFn: integrationData => services.updateCompanyIntegrationAPI(user.id, integrationData),
    onSuccess: () => {
      dispatch(messageActions.openMessage(MessageTypes.success, successMessage));
      dispatch(modalActions.closeModal());

      queryClient.invalidateQueries({
        queryKey: [constants.INTEGRATION_KEY],
        exact: true,
      });
    },
    onError: () => {
      dispatch(messageActions.openMessage(MessageTypes.error, errorMessage));
    },
  });
};

export const useEditEagleviewConnection = () => {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation<Partial<EagleviewConnection>[]>({
    mutationFn: variables => services.editEagleviewConnectionAPI(user.id, variables),
    onSuccess: () => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.success,
          intl.formatMessage({ id: I18nEnum.EagleviewWasEditedSuccessfully }),
        ),
      );
      dispatch(modalActions.closeModal());

      queryClient.invalidateQueries({
        queryKey: [constants.EAGLE_VIEW_CONNECTION_KEY],
        exact: true,
      });
    },
    onError: () => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.error,
          intl.formatMessage({ id: I18nEnum.UnfortunatelyTheEagleviewWasNotEdited }),
        ),
      );
    },
  });
};

export const useDisconnectEagleviewMutation = () => {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation<number[]>({
    mutationFn: ids => services.deleteEagleviewConnectionAPI(user.id, ids),
    onSuccess: () => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.success,
          intl.formatMessage({ id: I18nEnum.EagleViewWasDisconnectedSuccessfully }),
        ),
      );
      dispatch(modalActions.closeModal());

      queryClient.invalidateQueries({
        queryKey: [constants.EAGLE_VIEW_CONNECTION_KEY],
        exact: true,
      });
    },
    onError: () => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.error,
          intl.formatMessage({ id: I18nEnum.UnfortunatelyTheEagleViewWasNotDisconnected }),
        ),
      );
    },
  });
};

export const useUpsertJobNimbusIntegration = ({
  successMessage,
  errorMessage,
}: UseUpsertIntegrationProps) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation<{ body: Partial<JobNimbusIntegration>; callback: () => void }>({
    mutationFn: ({ body }) => services.updateCompanyJobNimbusIntegrationAPI(user.id, body),
    onSuccess: (_, { callback }) => {
      dispatch(messageActions.openMessage(MessageTypes.success, successMessage));

      queryClient.invalidateQueries({
        queryKey: [constants.JOBNIMBUS_CONNECTION_KEY],
        exact: true,
      });

      callback();
    },
    onError: () => {
      dispatch(messageActions.openMessage(MessageTypes.error, errorMessage));
    },
  });
};

export const useUpsertLeapIntegration = ({
  successMessage,
  errorMessage,
}: UseUpsertIntegrationProps) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation<{ body: Partial<LeapIntegration>; callback: () => void }>({
    mutationFn: ({ body }) => services.updateCompanyLeapIntegrationAPI(user.id, body),
    onSuccess: (_, { callback }) => {
      dispatch(messageActions.openMessage(MessageTypes.success, successMessage));

      queryClient.invalidateQueries({
        queryKey: [constants.LEAP_CONNECTION_KEY],
        exact: true,
      });

      callback();
    },
    onError: () => {
      dispatch(messageActions.openMessage(MessageTypes.error, errorMessage));
    },
  });
};

export const useUpsertOneClickContractorIntegration = ({
  successMessage,
  errorMessage,
}: UseUpsertIntegrationProps) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation<{ body: Partial<OneClickContractorIntegration>; callback: () => void }>({
    mutationFn: ({ body }) => services.updateCompanyOneClickContractorIntegrationAPI(user.id, body),
    onSuccess: (_, { callback }) => {
      dispatch(messageActions.openMessage(MessageTypes.success, successMessage));

      queryClient.invalidateQueries({
        queryKey: [constants.ONE_CLICK_CONTRACTOR_CONNECTION_KEY],
        exact: true,
      });

      callback();
    },
    onError: () => {
      dispatch(messageActions.openMessage(MessageTypes.error, errorMessage));
    },
  });
};

export const useGeneratePublicApiKey = () => {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation<{ name: string }, { data: { token: string } }>({
    mutationFn: variables => services.generatePublicApiKeyAPI(user.id, variables),
    onSuccess: () => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.success,
          intl.formatMessage({
            id: I18nEnum.TheChangesHaveBeenSaved,
          }),
        ),
      );
      queryClient.invalidateQueries({
        queryKey: [constants.PUBLIC_API_KEYS_KEY],
      });
    },
    onError: () => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.error,
          intl.formatMessage({ id: I18nEnum.UnfortunatelyTheChangesHaveNotBeenSaved }),
        ),
      );
    },
  });
};

export const useDeletePublicApiKey = (id: number) => {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.selectUser);

  return useAppMutation({
    mutationFn: () => services.deletePublicApiKeyAPI(user.id, id),
    onSuccess: () => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.success,
          intl.formatMessage({
            id: I18nEnum.RecordHasBeenDeleted,
          }),
        ),
      );
      queryClient.invalidateQueries({
        queryKey: [constants.PUBLIC_API_KEYS_KEY],
      });
      dispatch(modalActions.closeModal());
    },
    onError: () => {
      dispatch(
        messageActions.openMessage(
          MessageTypes.error,
          intl.formatMessage({ id: I18nEnum.UnfortunatelyTheRecordHasNotBeenDeleted }),
        ),
      );
    },
  });
};
