import { Line } from 'modules/product/types';
import { QuoteFeature } from 'modules/quoteSettings/types';
import { I18nEnum } from 'types';
import { BrandingConfig, MapSettings, WidgetSettings } from './toolScript';
import { FormDragAndDropValue } from 'components/Upload';

export * from './toolScript';

export interface WidgetSettingsState {
  loaded: boolean;
  demo?: boolean;
  branding: BrandingConfig;
  rawBranding?: WidgetSettings;
  products?: Line[];
  companyId?: number;
  wildcard?: string;
  domains: string[];
  iframePreview?: boolean;
  externalUrl?: string;
  userInfo?: Record<string, string | number>;
}

export interface WidgetSettingsConfigState {
  initialDemoViewType: string;
  notEditableFields: (keyof WidgetSettings)[];
  mockOCWidgetBrandingNotEditableFields: (keyof WidgetSettings)[];
  mockOCToolCalloutBrandingNotEditableFields: (keyof WidgetSettings)[];
  useOCBrandingToggleVisible: boolean;
  showUpgradeSubscriptionTooltip: boolean;
  demoViewTypeOptionsValue: string[];
  roofQuoteProButton: RoofQuoteProButtonSettingsConfig;
  callout: CalloutSettingsConfig;
}

interface RoofQuoteProButtonSettingsConfig {
  showFullSettings: boolean;
}

interface CalloutSettingsConfig {
  showFullSettings: boolean;
}

export type Products = Line[];

export enum TabKeys {
  ProductCustomizationTab = 'ProductCustomization',
  WidgetTab = 'Widget',
  Snippets = 'Snippets',
  Quotes = 'Quotes',
  ClpButton = 'ClpButton',
  Emails = 'Emails',
}
export interface SettingsLocationState {
  settingsToOpen: [TabKeys, QuoteFeature | undefined];
}

export type ValidatableFieldsToSectionTitleMap = Record<string, I18nEnum>;

export interface BrandingConfigForm extends Omit<BrandingConfig, 'mapSettings'> {
  mapSettings: MapSettings & {
    placeholderMedia: string | null | FormDragAndDropValue;
  };
}
