import { MouseEvent as ReactMouseEvent } from 'react';

export interface AuthFormProps {
  onFacebookCallback?: (responseFacebook: any) => void;
  onGoogleCallback?: (responseGoogle: any) => void;
  onLocalSignUp?: (userData: any) => void;
  onLocalSignIn?: (userData: SignInLocalUserData) => void;
  onCreateAccounLink?: (event: ReactMouseEvent<HTMLAnchorElement>) => void;
  onSignInLink?: (event: ReactMouseEvent<HTMLAnchorElement>) => void;
  onForgotPasswordLink?: (event: ReactMouseEvent<HTMLAnchorElement>) => void;
}

export interface SocialsLoginProps extends AuthFormProps {
  loginType: AuthType;
  buttonsDisabled?: boolean;
}

export enum Socials {
  facebook = 'facebook',
  google = 'google',
}

export enum AuthType {
  signIn = 'signIn',
  signUp = 'signUp',
}

export interface SignInLocalUserData {
  email: string;
  password: string;
}

export interface SignUpLocalUserData extends SignInLocalUserData {
  firstName: string;
  lastName: string;
  phone?: string;
  affiliateId?: number | string;
  affiliateLink?: string;
}

export type SignInSocialsUserData = {
  accessToken: string;
  googleId?: string;
  facebookId?: string;
  affiliateId?: number | string;
  affiliateLink?: string;
};

export interface EmailConfirmProps {
  userId: string | number;
  confirmationCode: string;
}

export interface ResetPasswordProps {
  userId: string | number;
  resetPasswordCode: string;
  expired?: boolean;
}

export interface ChangePasswordProps extends ResetPasswordProps {
  password: string;
}

export interface User {
  id: number;
  confirmationCode: string;
  email: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  password?: string;
  phone?: string;
  photo?: string;
  role: Role;
  createdAt: string;
  updatedAt: string;
  status: UserStatusEnum;
  hasAssignedLeads?: boolean;
  hasCreatedLeads?: boolean;
  CLPAccountStatus?: CLPAccountStatus;
}

export enum UserStatusEnum {
  Active = 'active',
  Deactivated = 'deactivated',
  Deleted = 'deleted',
}

export interface UserState extends User {
  iat: number;
  exp: number;
  /* TODO: change reducer structure to
  {
    error?: any;
    success: boolean;
    data: UserState;
  }
  */
  error?: any;
  success?: boolean;
}

interface Role {
  name: Roles;
  permissions: {
    module: AllPermissionsType;
    read: boolean;
    write: boolean;
  }[];
}

export enum Roles {
  companyOwner = 'company_owner',
  companyAdmin = 'company_admin',
  salesRep = 'sales_rep',
  productionRep = 'production_rep',
  admin = 'admin',
  superAdmin = 'super_admin',
}

export const AllPermissions = {
  companyProfile: 'company_profile',
  widgetSetting: 'widget_setting',
  developerInfo: 'developer_info',
  productsSelection: 'products_selection',
  billing: 'billing',
  billingUpdate: 'billing_update',
  financingSettings: 'financing_settings',
  analyticsReports: 'analytics_reports',
  customerStatistics: 'customer_satistics',
  repQuotes: 'rep_quotes',
  userProfile: 'user_profile',
  RQPLeads: 'RQP_leads',
  otherContacts: 'other_contacts',
  addressOnly: 'address_only',
  manageContractors: 'manage_contractors',
  adminProfile: 'admin_profile',
  contractorLoanPro: 'contractor_loan_pro',
  basicFinancing: 'basic_financing',
  manageSales: 'manage_sales',
  marketsSettings: 'markets_settings',
  orders: 'orders',
  ordersAccountsManagement: 'orders_accounts_management',
  importedContacts: 'imported_contacts',
} as const;

export type AllPermissionsType = typeof AllPermissions[keyof typeof AllPermissions];

export const CLPAccountStatuses = {
  noAccount: 'No Account',
  active: 'Active',
  deactivated: 'Deactivated',
} as const;

export type CLPAccountStatus = typeof CLPAccountStatuses[keyof typeof CLPAccountStatuses];

export const OAuthTypes = {
  google: 'google',
  outlook: 'outlook',
  office365: 'office365',
} as const;
export type OAuthType = typeof OAuthTypes[keyof typeof OAuthTypes];
