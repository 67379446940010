import { useSelector } from 'react-redux';

import { selectUser } from 'modules/auth/selectors';
import { useAppQuery } from 'modules/reactQuery/useAppQuery';
import { services, constants } from 'modules/developer';
import {
  BeaconConnection,
  IntegrationType,
  EagleviewConnection,
  JobNimbusIntegrationType,
  LeapIntegrationType,
  OneClickContractorIntegrationType,
  PublicToken,
} from 'modules/developer/types';

export const useGetBeaconConnection = () => {
  const user = useSelector(selectUser);

  return useAppQuery<BeaconConnection>(
    [constants.BEACON_CONNECTION_KEY],
    () => services.getBeaconConnectionAPI(user.id),
    {
      enabled: !!user.id,
    },
  );
};

export const useGetIntegration = () => {
  const user = useSelector(selectUser);

  return useAppQuery<IntegrationType>(
    [constants.INTEGRATION_KEY],
    () => services.getCompanyIntegrationAPI(user.id),
    {
      enabled: !!user.id,
    },
  );
};

export const useGetEagleviewConnections = () => {
  const user = useSelector(selectUser);

  return useAppQuery<EagleviewConnection[]>(
    [constants.EAGLE_VIEW_CONNECTION_KEY],
    () => services.getEagleViewConnectionsAPI(user.id),
    {
      enabled: !!user.id,
    },
  );
};

export const useGetJobNimbusIntegration = () => {
  const user = useSelector(selectUser);

  const { data: jobNimbusIntegration, isFetching } = useAppQuery<JobNimbusIntegrationType>(
    [constants.JOBNIMBUS_CONNECTION_KEY],
    () => services.getCompanyJobNimbusIntegrationAPI(user.id),
    {
      enabled: !!user.id,
    },
  );

  return !isFetching ? jobNimbusIntegration : undefined;
};

export const useGetLeapIntegration = () => {
  const user = useSelector(selectUser);

  const { data: leapIntegration, isFetching } = useAppQuery<LeapIntegrationType>(
    [constants.LEAP_CONNECTION_KEY],
    () => services.getCompanyLeapIntegrationAPI(user.id),
    {
      enabled: !!user.id,
    },
  );

  return !isFetching ? leapIntegration : undefined;
};

export const useGetOneClickContractorIntegration = () => {
  const user = useSelector(selectUser);

  const { data: oneClickContractorIntegration, isFetching } =
    useAppQuery<OneClickContractorIntegrationType>(
      [constants.ONE_CLICK_CONTRACTOR_CONNECTION_KEY],
      () => services.getCompanyOneClickContractorIntegrationAPI(user.id),
      {
        enabled: !!user.id,
      },
    );

  oneClickContractorIntegration?.settings?.jobTypes.forEach(jobType => {
    if (!jobType.org_job_object_link) {
      jobType.org_job_object_link = {
        sort_order: 1000,
      };
    }
  });

  return !isFetching ? oneClickContractorIntegration : undefined;
};

export const useGetPublicApiKeys = () => {
  const user = useSelector(selectUser);

  return useAppQuery<Omit<PublicToken, 'order'>[], PublicToken[]>(
    [constants.PUBLIC_API_KEYS_KEY],
    () => services.getPublicApiKeysAPI(user.id),
    {
      enabled: !!user.id,
      select: data => data.map((publicKey, index) => ({ ...publicKey, order: index + 1 })),
    },
  );
};
