import { ListQuery, PartialBy } from 'types';

import { FinancingType } from 'modules/financing/types';
import * as PriceSettingsTypes from 'modules/global/types';
import { SubscriptionCountry } from 'modules/subscription/types';

export interface Price {
  id: number;
  amount: number;
}

export interface PrimaryColor {
  id: number;
  code: string;
  name: string;
  rgb: string;
}

export interface SecondaryColor {
  id: number;
  code: string;
  name: string;
  rgb: string;
}

export interface ColorDefinition {
  id: number;
  code: string;
  name: string;
  primaryColor: PrimaryColor;
  secondaryColor: SecondaryColor;
}

export interface Manufacturer {
  id: number;
  code: string;
  name: string;
  slug: string;
  logo: string;
}

export interface Line extends DBProduct {
  id: number;
  algaeProtection: string;
  approxNailsPerSq: string;
  approxPiecesPerSq: number;
  approxWeightPerSq: string;
  awards: string;
  code: string;
  completionTimeFrame: number;
  discontinued: boolean;
  exposure: string;
  featuresExtendedWarranty: boolean;
  featuresImpactResistant: boolean;
  fireRating: string;
  grade: string;
  gradeDetails: string;
  inhouseDescription: string;
  manufacturerOverview: string;
  merchantFeeType: ProductMerchantFeeType;
  customMerchantFee: number;
  name: string;
  nominalDimensions: string;
  otherFeatures: string;
  slug: string;
  style: string;
  styleComplexity: number;
  topFeatures: string;
  type: string;
  warranty: number;
  warrantyDetails: string;
  windRating: number;
  manufacturer: Manufacturer;
  coloredLines: ColoredLine[];
  brochures?: Brochures;
  warrantyCoverage: ProductWarrantyCoverageType | null;
  //OwensCorning fields
  bundlesPerSq?: string;
  coveragePerSq?: string;
  standards?: { uid: string; name: string }[];
  documents?: { uid: string; name: string; url: string; title?: string }[];
}

export interface ProductDetailLink {
  url: string;
  label: string;
}

export interface CombinedByNameLine {
  productName: string;
  lines: { marketSlug: string; productId: number }[];
}

export interface DBProduct {
  id: number;
  defaultWarrantyDetails: string;
  defaultTopFeatures: string;
  defaultWhatsIncluded: string;
  whatsIncluded: string;
  defaultAdditionalCosts: string;
  additionalCosts: string;
  label: string;
  productLabel: Label | null;
  order: number;
  buttonText: string;
  price: number;
  customProduct?: CustomProduct;
  fixedPrice: boolean;
  showMonthly: boolean;
  showTotal: boolean;
  productName: string;
  loanProductId: number;
  includeMerchantFee: boolean;
  useInstantQuoteSettings: boolean;
  priceRangeEnabled: boolean;
  priceRangeValue: number | null;
  priceRangeType: PriceSettingsTypes.PriceRangeType | null;
  wasteFactorEnabled: boolean;
  wasteFactorValue: number | null;
  steepSlopeChargeEnabled: boolean;
  steepSlopeChargeValue: number | null;
  steepSlopeChargeType: PriceSettingsTypes.PriceType | null;
  addAdditionalCosts: boolean;
  productDetailLink: ProductDetailLink | null;
}

export interface ProductVideo {
  video: string;
  poster: string;
}

export interface BrandVideo {
  video: string;
  poster: string;
}

export interface Brochures {
  manufacture: string;
  manufactureName: string;
  productLine: string;
  productLineName: string;
}

export interface ProductImage {
  id: number;
  colorCode: string;
  image: string | null;
  order: number;
  product: {
    id: number;
  };
}

export interface ColoredLine {
  swatchImg: string;
  swatchImgWeb: string;
  swatchImgThumbnail: string;
  swatchImgThumbnailAnnotation: string;
  houseImg: string;
  houseImgWeb: string;
  name: string;
  lineName: string;
  recommendedProducts: any[];
  id: number;
  beaconId: string;
  bestSeller: boolean;
  chameleonId: string;
  discontinued: boolean;
  energyStarCertified: boolean;
  hubspotId: string;
  lineColorRank: number;
  outOfStock: boolean;
  personalized: boolean;
  popularity: number;
  recommendedSort: number;
  roofTone: number;
  price: Price;
  colorDefinition: ColorDefinition;
  line: Line;
  priceRange: string;
  productCode: string;
  productSlug: string;
  productVideo: ProductVideo;
  brandVideo: BrandVideo;
  hidden: boolean;
  productImages: ProductImage[];
  order: number;
}
export interface ProductState {
  selectedProducts: (Line | null)[];
  initialSelectedProducts: (Line | null)[];
  edited: boolean;
  labels: Label[];
  upsertCardView: UpsertCardView;
  upsertedProduct: Line | null;
  upsertedProductInd: number;
  newFinancingType?: FinancingType;
}

export interface FilterQuery {
  grade?: { [k: string]: boolean };
  manufacturer?: { [k: string]: boolean };
  type?: { [k: string]: boolean };
  countryAvailableIn?: string;
}

export interface CatalogListQuery extends Omit<ListQuery, 'sorting'> {
  filter: FilterQuery;
}

export type RequestProductsBodyType = {
  productName: string;
  type?: string;
};

export interface Label {
  id: number;
  name: string;
  isDefault: boolean;
}

export type PriceType = 'pricePerSquare' | 'fixedPrice';

export interface CustomProduct {
  id: number;
  additionalCosts: string;
  fixedPrice: boolean;
  houseImage: string;
  name: string;
  price: number;
  swatchImage: string;
  topFeatures: string;
  type: string;
  warranty: string;
  whatsIncluded: string;
  createdAt: string;
}

export enum UpsertCardView {
  none,
  createCustomCard,
  updateCustomCard,
  customizeCard,
}

export interface ProductDifferenceType {
  name: string;
  warranty: string;
  warrantyCoverage: ProductWarrantyCoverageType | null;
  topFeatures: string;
  productLabelId: number;
  price: number | string;
  whatsIncluded: string;
  additionalCosts: string;
  coloredLines: ColoredLine[];
  productImages: ProductImage[] | PartialBy<ProductImage, 'id'>[];
  fixedPrice: boolean;
  priceType: string;
  showTotal: boolean;
  showMonthly: boolean;
  loanProductId: number;
  includeMerchantFee: boolean;
  merchantFeeType: ProductMerchantFeeType;
  customMerchantFee: number | string;
  useInstantQuoteSettings: boolean;
  priceRangeEnabled: boolean;
  priceRangeValue: number | null;
  priceRangeType: PriceSettingsTypes.PriceRangeType | null;
  wasteFactorEnabled: boolean;
  wasteFactorValue: number | null;
  steepSlopeChargeEnabled: boolean;
  steepSlopeChargeValue: number | null;
  steepSlopeChargeType: PriceSettingsTypes.PriceType | null;
  addAdditionalCosts: boolean;
  productDetailLink: ProductDetailLink;
}

export type RequestProductDifferenceType = ProductDifferenceType;

export const enum TabKeys {
  roofleCatalog = 'RoofleCatalog',
  customCatalog = 'MyCatalog',
}

export interface WidgetProductSettings {
  selectedColoredLine: ColoredLine;
  isDisabledSubmitButton: boolean;
  isLoadingDetailsButton: boolean;
}

export interface WidgetProductState {
  [key: number]: WidgetProductSettings;
}

export enum ProductDetailsTabKeys {
  InstallationPackage = 'InstallationPackage',
  Financing = 'Financing',
  Specifications = 'Specifications',
  TotalProtection = 'TotalProtection',
  DesignInspiration = 'DesignInspiration',
  Warranty = 'Warranty',
}

export enum OwensCorningWidgetType {
  TotalProtection,
  DesignInspiration,
}

export enum ProductConfigRuleId {
  OwensCorningProduct = 'OC_PRODUCT',
}

export enum ProductConfigValidatorId {
  OwensCorningProductMustBeFirst = 'FIRST_OC_PRODUCT',
}

export type ProductConfigValidator = (selectedProducts: (Line | null)[]) => string | null;

export interface RuleConfig<T> {
  id: string;
  config: T;
}

export interface ManufactureLogoConfig {
  enabled: boolean;
}

export interface DisplayOrderConfig {
  strokeColor: string;
  filterColor: string;
}

interface SelectedProductCardStateStyles {
  strokeColor: string;
  dragHandlerPrimaryColor: string;
  dragHandlerFilterColor: string;
}

export interface SelectedProductCardStyles {
  inactiveState: SelectedProductCardStateStyles;
  activeState: SelectedProductCardStateStyles;
}

export interface BaseConfigWithRules<T> {
  default: T;
  rules?: RuleConfig<Partial<T>>[];
}

export interface ProductSettingsConfig {
  selectedProductsValidatorIds: string[];
  infoMessage: {
    enabled: boolean;
    translationKey?: string;
  };
  selectedProductCard: {
    displayOrder: BaseConfigWithRules<DisplayOrderConfig>;
    manufacturerLogo: BaseConfigWithRules<ManufactureLogoConfig>;
    styles: BaseConfigWithRules<SelectedProductCardStyles>;
  };
  countryAvailableIn: SubscriptionCountry;
  showFinancingTypeInfoTooltip: boolean;
}

export enum ProductWarrantyCoverageType {
  NoWarranty = 'NoWarranty',
  PlatinumProtectionLimitedWarranty = 'PlatinumProtectionLimitedWarranty',
  PreferredProtectionLimitedWarranty = 'PreferredProtectionLimitedWarranty',
  SystemProtectionLimitedWarranty = 'SystemProtectionLimitedWarranty',
  StandardProductLimitedWarranty = 'StandardProductLimitedWarranty',
}

export type ProductWarrantyConfigMap = Exclude<ProductWarrantyCoverageType, 'NoWarranty'>;

export enum ProductMerchantFeeType {
  Min = 'Min',
  Average = 'Average',
  Max = 'Max',
  Custom = 'Custom',
}

export const UpsertProductFormKey = {
  Name: 'name',
  ColoredLines: 'coloredLines',
  ProductLabelId: 'productLabelId',
  PriceType: 'priceType',
  Price: 'price',
  LoanProductId: 'loanProductId',
  IncludeMerchantFee: 'includeMerchantFee',
  MerchantFeeType: 'merchantFeeType',
  CustomMerchantFee: 'customMerchantFee',
  ShowTotal: 'showTotal',
  ShowMonthly: 'showMonthly',
  UseInstantQuoteSettings: 'useInstantQuoteSettings',
  SteepSlopeChargeEnabled: 'steepSlopeChargeEnabled',
  SteepSlopeChargeValue: 'steepSlopeChargeValue',
  SteepSlopeChargeType: 'steepSlopeChargeType',
  WasteFactorEnabled: 'wasteFactorEnabled',
  WasteFactorValue: 'wasteFactorValue',
  PriceRangeEnabled: 'priceRangeEnabled',
  PriceRangeValue: 'priceRangeValue',
  PriceRangeType: 'priceRangeType',
  AddAdditionalCosts: 'addAdditionalCosts',
  Type: 'type',
  WarrantyCoverage: 'warrantyCoverage',
  Warranty: 'warranty',
  TopFeatures: 'topFeatures',
  WhatsIncluded: 'whatsIncluded',
  AdditionalCosts: 'additionalCosts',
  ProductDetailLinkURL: ['productDetailLink', 'url'],
  ProductDetailLinkLabel: ['productDetailLink', 'label'],
};
