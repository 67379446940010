import { I18nEnum, ListQuery, ThunkResult } from 'types';
import { intl } from 'intl';
import {
  AvailableTransaction,
  constants,
  ConsumerPaymentRefundBody,
  ConsumerPaymentRequestBody,
  CreateMerchantBody,
  CreateOfferCodeBody,
  FinancingSetting,
  InviteConsumerBody,
  LoanApplication,
  Merchant,
  MomntItem,
  OfferCode,
  PriceInfo,
  RateSheet,
  selectors,
  services,
  Statement,
  Transaction,
  UpdateMerchantRateSheetBody,
  UpdateOfferCodeBody,
  UpsertPaymentDetailsBody,
  VerifyMicroDepositsBody,
  LoanInvitation,
} from '.';
import { actions as spinnerActions } from 'modules/spinner';
import { actions as modalActions, ModalTypes } from 'modules/modal';
import { selectors as userSelectors } from 'modules/auth';
import { SupportRequestedType } from 'modules/email/types';
import { actions as messageActions, MessageTypes } from 'modules/message';
import { actions as marketActions, selectors as marketSelectors } from 'modules/markets';
import { selectors as widgetSelectors } from 'modules/widget';
import {
  QuickQuoteUserInfo,
  selectors as quickQuoteSelectors,
  actions as quickQuoteActions,
} from 'modules/quickQuote';
import { Line } from 'modules/product';
import { getStatisticsIdFromStorage } from 'utils';

export const setBasicFinancing = (basicFinancing: FinancingSetting | null) => ({
  type: constants.SET_BASIC_FINANCING,
  basicFinancing,
});

export const setMomntMerchant = (merchant: Merchant) => ({
  type: constants.SET_MOMNT_MERCHANT,
  merchant,
});

export const setLoanApplications = ({ data, total, loaded }: MomntItem<LoanApplication>) => ({
  type: constants.SET_MOMNT_LOAN_APPLICATIONS,
  loanApplications: {
    data,
    total,
    loaded,
  },
});

export const setLoanInvitations = ({ data, total, loaded }: MomntItem<LoanInvitation>) => ({
  type: constants.SET_MOMNT_LOAN_INVITATIONS,
  loanInvitations: {
    data,
    total,
    loaded,
  },
});

export const setAllTransactions = ({ data, total, loaded }: MomntItem<Transaction>) => ({
  type: constants.SET_MOMNT_ALL_TRANSACTIONS,
  allTransactions: {
    data,
    total,
    loaded,
  },
});

export const setAvailableTransactions = ({
  data,
  total,
  loaded,
}: MomntItem<AvailableTransaction>) => ({
  type: constants.SET_MOMNT_AVAILABLE_TRANSACTIONS,
  availableTransactions: {
    data,
    total,
    loaded,
  },
});

export const setRecentActivity = ({ data, total, loaded }: MomntItem<Transaction>) => ({
  type: constants.SET_MOMNT_RECENT_ACTIVITY,
  recentActivity: {
    data,
    total,
    loaded,
  },
});

export const setOfferCodes = ({ data, total, loaded }: MomntItem<OfferCode>) => ({
  type: constants.SET_MOMNT_OFFER_CODES,
  offerCodes: {
    data,
    total,
    loaded,
  },
});

export const setStatements = ({ data, total, loaded }: MomntItem<Statement>) => ({
  type: constants.SET_MOMNT_STATEMENTS,
  statements: {
    data,
    total,
    loaded,
  },
});

export const setRateSheets = (rateSheets: RateSheet[]) => ({
  type: constants.SET_MOMNT_RATE_SHEETS,
  rateSheets,
});

export const getFinancing =
  (marketSlug: string): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    try {
      dispatch(spinnerActions.show());
      const user = userSelectors.selectUser(getState());
      const { data } = await services.getFinancingAPI(user.id, marketSlug);
      dispatch(setBasicFinancing(data));
    } catch (err) {
      dispatch(setBasicFinancing(null));
    }
    dispatch(spinnerActions.hide());
  };

export const saveFinancing =
  (marketSlug: string, body: FinancingSetting, callback: () => void): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();

    try {
      dispatch(spinnerActions.show());
      const user = userSelectors.selectUser(state);

      await services.saveFinancingAPI({
        userId: user.id,
        marketSlug,
        financing: body,
      });
      // markets update triggers getFinancing on the page level
      await dispatch(marketActions.getUserMarkets());
      dispatch(
        modalActions.openModal(ModalTypes.successModal, {
          subtitle: I18nEnum.TheChangesHaveBeenSaved,
          description: intl.formatMessage({
            id: I18nEnum.AllYourSettingsConfigurationHaveBeenAppliedToTheWidget,
          }),
        }),
      );
      callback();
    } catch (err) {
      dispatch(
        modalActions.openModal(ModalTypes.error, {
          action: saveFinancing,
          args: [marketSlug, body],
          error: err.message,
          requestBody: body,
          type: SupportRequestedType.saveFinancing,
          title: I18nEnum.UnfortunatelyYourChangesHaveNotBeen,
        }),
      );
    }
    dispatch(spinnerActions.hide());
  };

export const resetFinancingSettings =
  (id: number, callback?: () => Promise<void>): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();

    try {
      dispatch(spinnerActions.show());
      const user = userSelectors.selectUser(state);
      await services.resetFinancingSettingsAPI(user.id, id);
      // markets update triggers getFinancing on the page level
      await dispatch(marketActions.getUserMarkets());
      callback && (await callback());
    } catch (err) {}
    dispatch(modalActions.closeModal());
    dispatch(spinnerActions.hide());
  };

export const applyFinancingToMarkets =
  (
    marketSlugs: string[],
    financing: Partial<FinancingSetting>,
    switchingToBasicFinancingMarkets: string[],
  ): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const message = {
      type: MessageTypes.success,
      text: I18nEnum.ChangesHaveBeenApplied,
    };

    try {
      dispatch(spinnerActions.show());
      const user = userSelectors.selectUser(state);

      await services.applyFinancingToMarketsAPI({
        userId: user.id,
        marketSlugs,
        financing,
      });

      if (switchingToBasicFinancingMarkets?.length) {
        await services.switchToBasicFinancingAPI({
          userId: user.id,
          marketSlugs: switchingToBasicFinancingMarkets,
        });
      }

      // markets update triggers getFinancing on the page level
      await dispatch(marketActions.getUserMarkets());
      dispatch(modalActions.closeModal());
    } catch (err) {
      message.type = MessageTypes.error;
      message.text = I18nEnum.UnfortunatelyTheChanges;
    }
    dispatch(spinnerActions.hide());
    dispatch(messageActions.openMessage(message.type, message.text));
  };

export const applyLoanProductToMarkets =
  (
    marketsToApply: { [marketSlug: string]: number[] },
    loanProductId: number,
  ): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const message = {
      type: MessageTypes.success,
      text: I18nEnum.ChangesHaveBeenApplied,
    };

    try {
      dispatch(spinnerActions.show());
      const user = userSelectors.selectUser(state);

      await services.applyLoanProductToMarketsAPI({
        userId: user.id,
        marketsToApply,
        loanProductId,
      });
      // markets update triggers getFinancing on the page level
      await dispatch(marketActions.getUserMarkets());
      dispatch(modalActions.closeModal());
    } catch (err) {
      message.type = MessageTypes.error;
      message.text = I18nEnum.UnfortunatelyTheChanges;
    }
    dispatch(spinnerActions.hide());
    dispatch(messageActions.openMessage(message.type, message.text));
  };

export const switchToContractorLoanPro =
  (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
    const state = getState();
    const message = {
      type: MessageTypes.success,
      text: I18nEnum.ChangesHaveBeenApplied,
    };

    try {
      dispatch(spinnerActions.show());
      const user = userSelectors.selectUser(state);
      const markets = marketSelectors.selectMarkets(state);

      await services.applyLoanProductToMarketsAPI({
        userId: user.id,
        marketsToApply: markets.reduce((acc, market) => ({ ...acc, [market.market.slug]: [] }), {}),
        loanProductId: constants.BasePackage,
      });

      // markets update triggers getFinancing on the page level
      await dispatch(marketActions.getUserMarkets());
      dispatch(modalActions.closeModal());
    } catch (err) {
      message.type = MessageTypes.error;
      message.text = I18nEnum.UnfortunatelyTheChanges;
    }
    dispatch(spinnerActions.hide());
    dispatch(messageActions.openMessage(message.type, message.text));
  };

export const switchToBasicFinancing =
  (marketSlugs?: string[]): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const message = {
      type: MessageTypes.success,
      text: I18nEnum.ChangesHaveBeenApplied,
    };

    try {
      dispatch(spinnerActions.show());
      const user = userSelectors.selectUser(state);
      const markets = marketSelectors.selectMarkets(state);

      await services.switchToBasicFinancingAPI({
        userId: user.id,
        marketSlugs: marketSlugs || markets.map(market => market.market.slug),
      });

      // markets update triggers getFinancing on the page level
      await dispatch(marketActions.getUserMarkets());
      dispatch(modalActions.closeModal());
    } catch (err) {
      message.type = MessageTypes.error;
      message.text = I18nEnum.UnfortunatelyTheChanges;
    }
    dispatch(spinnerActions.hide());
    dispatch(messageActions.openMessage(message.type, message.text));
  };

export const getMerchantDetails = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  const state = getState();
  try {
    dispatch(spinnerActions.show());
    const user = userSelectors.selectUser(state);

    const { data: merchant } = await services.getMerchantDetailsAPI(user.id);

    dispatch(setMomntMerchant(merchant));
  } catch (err) {}
  dispatch(spinnerActions.hide());
};

export const createMerchant =
  (body: CreateMerchantBody): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const message = {
      type: MessageTypes.success,
      text: I18nEnum.YourRequestHasBeenSent,
    };
    try {
      dispatch(spinnerActions.show());
      const user = userSelectors.selectUser(state);

      await services.createMerchantAPI(body, user.id);
      dispatch(getMerchantDetails());
    } catch (err) {
      message.type = MessageTypes.error;
      message.text = I18nEnum.UnfortunatelyTheRequestHasNot;
    }
    dispatch(spinnerActions.hide());
    dispatch(messageActions.openMessage(message.type, message.text));
  };

export const getMerchantLoanApplications =
  (query: ListQuery): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    try {
      dispatch(spinnerActions.show());
      const user = userSelectors.selectUser(state);

      const { data } = await services.getMerchantLoanApplicationsAPI(user.id, query);
      dispatch(
        setLoanApplications({ data: data.loanApplications, total: data.total, loaded: true }),
      );
    } catch (err) {
      dispatch(setLoanApplications({ data: [], total: 0, loaded: false }));
    }
    dispatch(spinnerActions.hide());
  };

export const getMerchantLoanInvitations =
  (query: ListQuery): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    try {
      dispatch(spinnerActions.show());
      const user = userSelectors.selectUser(state);

      const { data } = await services.getMerchantLoanInvitationsAPI(user.id, query);
      dispatch(setLoanInvitations({ data: data.loanInvitations, total: data.total, loaded: true }));
    } catch (err) {
      dispatch(setLoanInvitations({ data: [], total: 0, loaded: false }));
    }
    dispatch(spinnerActions.hide());
  };

export const getMerchantAllTransactions =
  (query: ListQuery): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    try {
      dispatch(spinnerActions.show());
      const user = userSelectors.selectUser(state);

      const { data } = await services.getMerchantAllTransactionsAPI(user.id, query);
      dispatch(setAllTransactions({ data: data.allTransactions, total: data.total, loaded: true }));
    } catch (err) {
      dispatch(setAllTransactions({ data: [], total: 0, loaded: false }));
    }
    dispatch(spinnerActions.hide());
  };

export const getMerchantAvailableTransactions =
  (query: ListQuery): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    try {
      dispatch(spinnerActions.show());
      const user = userSelectors.selectUser(state);

      const { data } = await services.getMerchantAvailableTransactionsAPI(user.id, query);
      dispatch(
        setAvailableTransactions({
          data: data.availableTransactions,
          total: data.total,
          loaded: true,
        }),
      );
    } catch (err) {
      dispatch(setAvailableTransactions({ data: [], total: 0, loaded: false }));
    }
    dispatch(spinnerActions.hide());
  };

export const getMerchantRecentActivity =
  (query: ListQuery): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    try {
      dispatch(spinnerActions.show());
      const user = userSelectors.selectUser(state);

      const { data } = await services.getMerchantRecentActivityAPI(user.id, query);
      dispatch(
        setRecentActivity({
          data: data.recentActivity,
          total: data.total,
          loaded: true,
        }),
      );
    } catch (err) {
      dispatch(setRecentActivity({ data: [], total: 0, loaded: false }));
    }
    dispatch(spinnerActions.hide());
  };

export const getMerchantOfferCodes =
  (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
    const state = getState();
    try {
      dispatch(spinnerActions.show());
      const user = userSelectors.selectUser(state);

      const { data } = await services.getMerchantOfferCodesAPI(user.id);
      dispatch(
        setOfferCodes({
          data: data,
          total: data.length,
          loaded: true,
        }),
      );
    } catch (err) {
      dispatch(setOfferCodes({ data: [], total: 0, loaded: false }));
    }
    dispatch(spinnerActions.hide());
  };

export const upsertMerchantOfferCode =
  (
    body: UpdateOfferCodeBody | CreateOfferCodeBody,
    offerCodeId?: number,
  ): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const message = {
      type: MessageTypes.success,
      text: offerCodeId
        ? !body.isDeleted
          ? I18nEnum.OfferCodeHasBeenUpdated
          : I18nEnum.OfferCodeHasBeenRemoved
        : I18nEnum.OfferCodeHasBeenCreated,
    };
    try {
      dispatch(spinnerActions.show());
      const user = userSelectors.selectUser(state);

      await (offerCodeId
        ? services.updateMerchantOfferCodeAPI(user.id, offerCodeId, body)
        : services.createMerchantOfferCodeAPI(user.id, body as CreateOfferCodeBody));

      dispatch(modalActions.closeModal());
    } catch (err) {
      message.type = MessageTypes.error;
      message.text = offerCodeId
        ? !body.isDeleted
          ? I18nEnum.UnfortunatelyTheOfferCodeHasNotBeenUpdated
          : I18nEnum.UnfortunatelyTheOfferCodeHasNotBeenRemoved
        : I18nEnum.UnfortunatelyTheOfferCodeHasNotBeenCreated;
    }
    dispatch(getMerchantOfferCodes());
    dispatch(spinnerActions.hide());
    dispatch(messageActions.openMessage(message.type, message.text));
  };

export const inviteConsumer =
  (body: InviteConsumerBody): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    try {
      dispatch(spinnerActions.show());
      const user = userSelectors.selectUser(state);

      await services.inviteConsumerAPI(user.id, body);
    } catch (err) {
      throw Error(err);
    } finally {
      dispatch(spinnerActions.hide());
    }
  };

export const inviteConsumerQuickQuote =
  ({
    line,
    phone,
    email = '',
    priceInfo,
    userInfo: _userInfo,
  }: {
    line: Pick<Line, 'id' | 'loanProductId'>;
    phone?: string;
    email?: string;
    priceInfo: PriceInfo;
    userInfo?: QuickQuoteUserInfo | null;
  }): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const { selectConfig } = await import('modules/quickQuoteConfig/selectors');
    const state = getState();
    const offerCodes = selectors.selectOfferCodes(state);
    const userInfo = _userInfo || quickQuoteSelectors.selectUserInfo(state);
    const { wildcard } = widgetSelectors.selectWidget(state);
    const statisticsId = await getStatisticsIdFromStorage();
    const {
      productCard: {
        instantLoan: { shouldSendEmail },
      },
    } = selectConfig(state);

    let offerCode = '';

    if (line.loanProductId > 0) {
      const productOfferCode = (offerCodes.data || []).find(item => item.id === line.loanProductId);

      productOfferCode && (offerCode = productOfferCode.name);
    }

    const message = {
      type: MessageTypes.success,
      text: I18nEnum.TheInvitationHasBeenSend,
    };

    try {
      dispatch(spinnerActions.show());

      if (wildcard) {
        const amount =
          priceInfo.total && priceInfo.priceRange?.showPriceRange
            ? priceInfo.priceRange.totalMax
            : priceInfo.total;
        await services.inviteConsumerWidgetAPI(wildcard, {
          phone: phone === undefined ? userInfo?.phone : phone,
          email,
          offerCode,
          amount,
          shouldSendEmail,
          statisticsId: statisticsId || undefined,
        });

        quickQuoteActions.markStatisticsProductAsInterestedInFinancing({
          productId: line.id,
          priceInfo,
        });
      }
    } catch (err) {
      message.type = MessageTypes.error;
      message.text = I18nEnum.UnfortunatelyTheInvitationHasNotBeenSent;
      throw Error(err);
    } finally {
      dispatch(spinnerActions.hide());
      dispatch(messageActions.openMessage(message.type, message.text));
    }
  };

export const getMerchantStatements =
  (query: ListQuery): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    try {
      dispatch(spinnerActions.show());
      const user = userSelectors.selectUser(state);

      const { data } = await services.getMerchantStatements(user.id, query);
      dispatch(
        setStatements({
          data: data.statements,
          total: data.total,
          loaded: true,
        }),
      );
    } catch (err) {
      dispatch(setStatements({ data: [], total: 0, loaded: false }));
    }
    dispatch(spinnerActions.hide());
  };

export const getMerchantRateSheets =
  (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
    const state = getState();
    try {
      dispatch(spinnerActions.show());
      const user = userSelectors.selectUser(state);

      const { data } = await services.getMerchantRateSheetsAPI(user.id);
      dispatch(setRateSheets(data));
    } catch (err) {
      dispatch(setRateSheets([]));
    }
    dispatch(spinnerActions.hide());
  };

export const updateMerchantRateSheet =
  (body: UpdateMerchantRateSheetBody): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const message = {
      type: MessageTypes.success,
      text: body.rateSheets[0].isActive
        ? I18nEnum.RateSheetHasBeenAdded
        : I18nEnum.RateSheetHasBeenRemoved,
    };
    try {
      dispatch(spinnerActions.show());
      const user = userSelectors.selectUser(state);

      await services.updateMerchantRateSheetAPI(user.id, body);
      dispatch(modalActions.closeModal());
      dispatch(getMerchantRateSheets());
    } catch (err) {
      message.type = MessageTypes.error;
      message.text = body.rateSheets[0].isActive
        ? I18nEnum.UnfortunatelyTheRateSheetHasNotBeenAdded
        : I18nEnum.UnfortunatelyTheRateSheetHasNot;
    }
    dispatch(spinnerActions.hide());
    dispatch(messageActions.openMessage(message.type, message.text));
  };

export const setDefaultMerchantRateSheets =
  (body: UpdateMerchantRateSheetBody): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();

    try {
      dispatch(spinnerActions.show());
      const user = userSelectors.selectUser(state);

      await services.updateMerchantRateSheetAPI(user.id, body);

      dispatch(getMerchantRateSheets());
    } catch (err) {}
    dispatch(spinnerActions.hide());
  };

export const createConsumerPaymentRequest =
  (body: ConsumerPaymentRequestBody): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const message = {
      type: MessageTypes.success,
      text: I18nEnum.YourRequestHasBeenSent,
    };
    try {
      dispatch(spinnerActions.show());
      const user = userSelectors.selectUser(state);

      await services.createConsumerPaymentRequestAPI(user.id, body);
      dispatch(modalActions.closeModal());
    } catch (err) {
      message.type = MessageTypes.error;
      message.text = I18nEnum.UnfortunatelyTheRequestHasNot;
    }
    dispatch(spinnerActions.hide());
    dispatch(messageActions.openMessage(message.type, message.text));
  };

export const createConsumerPaymentRefund =
  (body: ConsumerPaymentRefundBody): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const message = {
      type: MessageTypes.success,
      text: I18nEnum.RefundtHasBeenSent,
    };
    try {
      dispatch(spinnerActions.show());
      const user = userSelectors.selectUser(state);

      await services.createConsumerPaymentRefundAPI(user.id, body);
      dispatch(modalActions.closeModal());
    } catch (err) {
      message.type = MessageTypes.error;
      message.text = I18nEnum.UnfortunatelyRefundHasNot;
    }
    dispatch(spinnerActions.hide());
    dispatch(messageActions.openMessage(message.type, message.text));
  };

export const getQuickQuoteMerchantOfferCodes =
  (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
    const state = getState();
    try {
      dispatch(spinnerActions.show());
      const { wildcard } = widgetSelectors.selectWidget(state);

      if (wildcard) {
        const { data } = await services.getWidgetMerchantOfferCodesAPI(wildcard);
        dispatch(
          setOfferCodes({
            data: data,
            total: data.length,
            loaded: true,
          }),
        );
      }
    } catch (err) {
      dispatch(setOfferCodes({ data: [], total: 0, loaded: false }));
    }
    dispatch(spinnerActions.hide());
  };

export const getQuickQuoteMerchantRateSheets =
  (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
    const state = getState();
    try {
      dispatch(spinnerActions.show());
      const { wildcard } = widgetSelectors.selectWidget(state);

      if (wildcard) {
        const { data } = await services.getWidgetMerchantRateSheetsAPI(wildcard);
        dispatch(setRateSheets(data));
      }
    } catch (err) {
      dispatch(setRateSheets([]));
    }
    dispatch(spinnerActions.hide());
  };

export const verifyMicroDeposits =
  (body: VerifyMicroDepositsBody): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    try {
      dispatch(spinnerActions.show());
      const user = userSelectors.selectUser(state);
      await services.verifyMicroDepositsAPI(user.id, body);
    } catch (err) {}
    dispatch(spinnerActions.hide());
    dispatch(getMerchantDetails());
  };

export const initiateMicroDeposits =
  (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
    const state = getState();
    try {
      dispatch(spinnerActions.show());
      const user = userSelectors.selectUser(state);
      await services.initiateMicroDepositsAPI(user.id);
    } catch (err) {}
    dispatch(spinnerActions.hide());
    dispatch(getMerchantDetails());
  };

export const upsertPaymentDetails =
  (body: UpsertPaymentDetailsBody, callback: () => void): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const message = {
      type: MessageTypes.success,
      text: I18nEnum.ChangesHaveBeenSentSuccessfully,
    };
    try {
      dispatch(spinnerActions.show());
      const user = userSelectors.selectUser(state);
      await services.upsertPaymentDetailsAPI(user.id, body);

      callback();

      dispatch(getMerchantDetails());
    } catch (err) {
      message.type = MessageTypes.error;
      message.text = I18nEnum.UnfortunatelyTheChangesHaveNotBeenSent;
    }
    dispatch(spinnerActions.hide());
  };
