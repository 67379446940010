import * as services from './services';
import * as constants from './constants';
import * as selectors from './selectors';
import * as actions from './actions';
import * as queries from './queries';
import { Line, ProductDifferenceType } from './types';

export { services, constants, selectors, actions, queries };
export * from './types';

export const hasDuplicates = (
  line: Line,
  selectedProducts: (Line | null)[],
  productsChanges?: ProductDifferenceType,
) => {
  const changes = (productsChanges && productsChanges[line.id]) || {};

  return (
    selectedProducts.filter(item => {
      const itemChanges = (item && productsChanges && productsChanges[item.id]) || {};
      return (
        item &&
        item.code === line.code &&
        (item.productName || `${item.manufacturer.name} ${item.name}`) ===
          (line.productName || `${line.manufacturer.name} ${line.name}`) &&
        item.manufacturer.code === line.manufacturer.code &&
        (itemChanges.warranty || item.warrantyDetails) ===
          (changes.warranty || line.warrantyDetails) &&
        (itemChanges.whatsIncluded || item.whatsIncluded || '') ===
          (changes.whatsIncluded || line.whatsIncluded || '')
      );
    }).length > 1
  );
};

export const MAX_PRICE = 10000;
