import { MAX_PRICE } from 'modules/product';
import { MAX_VALID_VALUE } from 'global-constants';

export const validateInteger = (value?: string): boolean =>
  !!value && (value === '0' || /^[1-9]\d*$/.test(value));

export const validateDecimalPrice = (value?: string): boolean =>
  !!value && /(^[1-9]\d{0,7}(\.\d{0,2})?$)|(^0(\.\d{0,2})?$)/.test(value);

export const validatePrice = (price: string) =>
  +price >= 1 && +price <= MAX_PRICE && validateDecimalPrice(price);

export const validatePriceIncludingZero = (price: string) =>
  price !== '' && +price >= 0 && +price <= MAX_PRICE;

export const validateFixedPrice = (price: string) =>
  price !== '' && +price >= 0 && +price <= MAX_VALID_VALUE && validateDecimalPrice(price);

export const _validateWebsite = (url: string) =>
  /^\s*(?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?\s*$/i.test(
    url,
  );

export const _validateDomain = (domain: string) =>
  /^\s*((?!-)[a-zA-Z0-9-]{1,62}[a-zA-Z0-9]\.)+[A-Za-z]{2,63}\s*$/.test(domain);

export const validateDecimalNumberRange = (
  value: string | number,
  min: number,
  max: number,
): boolean => {
  if (isNaN(+value)) {
    return false;
  }

  const numericValue = +value;

  return (
    numericValue >= min &&
    numericValue <= max &&
    /(^[0-9]\d{0,}(\.\d{0,2})?$)|(^0(\.\d{0,2})?$)/.test(String(value))
  );
};

export const validateMerchantFeeRange = (
  value: string | number,
  min: number,
  max: number,
): boolean => {
  if (isNaN(+value)) {
    return false;
  }

  const numericValue = +value;

  return (
    numericValue >= min &&
    numericValue <= max &&
    /(^[0-9]\d{0,}(\.\d{0,4})?$)|(^0(\.\d{0,4})?$)/.test(String(value))
  );
};
