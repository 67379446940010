import api from 'services/api';

import { UserWidgetSetting, ProductsEmailBody } from './types';

export const getUserWidgetSettingsAPI = (userId: number) =>
  api.get(`user/${userId}/user-widget-settings`);

export const upsertUserWidgetSettingsAPI = (userId: number, body: Partial<UserWidgetSetting>) =>
  api.post(`user/${userId}/user-widget-settings`, body);

export const getProductsDetailsEmailPreview = (userId: number, body: ProductsEmailBody) =>
  api.post(`/user/${userId}/products-email-preview`, body);
