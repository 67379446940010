import api from 'services/api';
import { ExportType, ListQuery } from 'types';
import {
  MeasurementsOrderBody,
  MeasurementsReportType,
  NoteRequestBody,
  PartialStatistics,
  Statistics,
  StatisticsType,
  ImportedContactsQuery,
  StatisticsStatusType,
} from './types';
import { decryptQQ } from 'modules/quickQuote/_utils';

export const getStatisticsAPI = (userId: number, params?: ListQuery & { type: StatisticsType }) =>
  api.get(`user/${userId}/statistics`, {
    params,
  });

export const exportStatisticsAPI = (
  userId: number,
  params?: ListQuery & Record<string, StatisticsType | ExportType | number[]>,
) =>
  api.get(`user/${userId}/statistics/export`, {
    params,
    responseType: 'blob',
    paramsSerializer: params => {
      const urlObj = new URLSearchParams(params);

      if (params.filter) {
        urlObj.set('filter', JSON.stringify(params.filter));
      }

      return urlObj.toString();
    },
  });

export const getStatisticsFiltersOptionsAPI = (
  userId: number,
  params: ListQuery & { type: StatisticsType },
) => api.get(`user/${userId}/dashboard/filters`, { params });

export const reassignStatisticsAPI = ({
  userId,
  statisticsIds,
  newOwnerId,
}: {
  userId: number;
  statisticsIds: number[];
  newOwnerId: number | null;
}) => api.patch(`user/${userId}/statistics/reassign`, { statisticsIds, newOwnerId });

export const updateStatisticsAPI = ({
  userId,
  statisticsId,
  type,
  body,
}: {
  userId: number;
  statisticsId: number;
  type: StatisticsType;
  body: Omit<PartialStatistics, 'additionalInformation'> & {
    additionalInformation?: string;
  };
}): Promise<Statistics> => api.patch(`user/${userId}/statistics/${statisticsId}/${type}`, body);

export const updateStatisticsScreenshotAPI = ({
  userId,
  statisticsId,
  mapScreenshot,
}: {
  userId: number;
  statisticsId: number;
  mapScreenshot: string;
}): Promise<Statistics> =>
  api.patch(`/user/${userId}/statistics-screenshot/${statisticsId}`, { mapScreenshot });

export const getStatisticsByIdAPI = (userId: number, statisticsId: number) =>
  api.get(`user/${userId}/statistics/${statisticsId}`).then(({ data }) =>
    data?.additionalInformation
      ? decryptQQ(data.additionalInformation).then(additionalInformationData => ({
          data: {
            ...data,
            additionalInformation: additionalInformationData,
          },
        }))
      : { data },
  );

export const getLatestUsedPriceRangeSettings = (
  userId: number,
): Promise<{ data: { priceRangeEnabled: boolean } }> =>
  api.get(`/user/${userId}/statistics-info/latest-price-range-settings`);

// Measurements
export const measurementsGetAvailableServices = (userId, statisticsId: number) =>
  api.post(`user/${userId}/measurement/available-services`, { statisticsId });

export const orderMeasurements = (
  userId,
  reportType: MeasurementsReportType,
  body: MeasurementsOrderBody,
) => api.post(`user/${userId}/measurement/${reportType}/order`, body);

export const getMeasurementOrdersByStatisticsId = (userId, statisticsId: number) =>
  api.get(`user/${userId}/measurement/orders/${statisticsId}`);

export const getMeasurementLastOrderByStatisticsId = (userId, statisticsId: number) =>
  api.get(`user/${userId}/measurement/last-order/${statisticsId}`);

export const downloadMeasurementOrderFile = (
  userId,
  reportType: MeasurementsReportType,
  orderId: number,
  fileName: string,
) =>
  api.get(
    `user/${userId}/measurement/${reportType}/download/${orderId}/${encodeURIComponent(fileName)}`,
    {
      responseType: 'blob',
    },
  );

export const checkGAFQuickMEasureSiteStatus = userId =>
  api.get(`user/${userId}/gaf-quick-measure/check-site-status`);

export const getBeaconOrderForStatisticsAPI = (userId: number, statisticsId: number) =>
  api.get(`user/${userId}/beacon/order/statistics/${statisticsId}`);

export const getLeadScreenshot = (userId: number, screenshotKey: string) =>
  api.get(`${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}user/${userId}/${screenshotKey}`, {
    responseType: 'blob',
  });

export const getQuotesForStatisticsAPI = (userId: number, statisticsId: number) =>
  api.get(`user/${userId}/statistics/${statisticsId}/quotes`);

export const getImportedContactsAPI = (userId: number, params?: ImportedContactsQuery) =>
  api.get(`user/${userId}/imported-contacts`, {
    params,
  });

export const getImportedContactsFiltersAPI = (userId: number, params?: ImportedContactsQuery) =>
  api.get(`user/${userId}/imported-contacts/filters`, {
    params,
  });

export const updateImportedContactStatusAPI = ({
  userId,
  importedContactId,
  status,
}: {
  userId: number;
  importedContactId: number;
  status: StatisticsStatusType;
}): Promise<Statistics> =>
  api.patch(`/user/${userId}/imported-contact/${importedContactId}/status`, { status });

export const importContactsAPI = (userId: number, body: FormData) =>
  api.post(`/user/${userId}/import-contacts`, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const getStatisticsNotes = (userId: number, statisticsId: number) =>
  api.get(`/user/${userId}/statistics/${statisticsId}/notes`);

export const saveStatisticsNote = ({
  userId,
  statisticsId,
  body,
}: {
  userId: number;
  statisticsId: number;
  body: NoteRequestBody;
}) => api.put(`/user/${userId}/statistics/${statisticsId}/note`, body);

export const deleteStatisticsNote = ({ userId, noteId }: { userId: number; noteId: number }) =>
  api.delete(`/user/${userId}/note/${noteId}`);
