import { FC, ReactNode, useState, ComponentType, useEffect, ReactElement } from 'react';
import styled from 'styled-components';
import {
  useFloating,
  autoUpdate,
  offset,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useInteractions,
  useClick,
  FloatingPortal,
  Placement,
  safePolygon,
  useTransitionStatus,
} from '@floating-ui/react';
import { Options as OffsetOptions } from '@floating-ui/core/src/middleware/offset';
import { Options as FlipOptions } from '@floating-ui/core/src/middleware/flip';

import CloseIconBase from 'components/Icons/CloseIcon';
import { TooltipCommonStyles } from './HoverTooltipStyles';
import { HandleCloseFn } from '@floating-ui/react/src/hooks/useHover';

type WhiteSpace = 'normal' | 'pre' | 'nowrap' | 'pre-wrap' | 'pre-line' | 'break-spaces';
export interface ITooltipProps {
  children: ReactElement;
  tooltip: ReactNode;
  TriggerComponent?: ComponentType;
  ContainerComponent?: ComponentType;
  placement?: Placement;
  trigger?: 'hover' | 'click' | 'manual';
  isShown?: boolean;
  offsetOptions?: OffsetOptions;
  flipOptions?: Partial<FlipOptions>;
  whiteSpace?: WhiteSpace;
  handleClose?: HandleCloseFn | null;
  isShouldShowOnMobile?: boolean;
  className?: string;
  floatingPortalRoot?: HTMLElement | null;
  triggerComponentProps?: Record<string, string | number | boolean | undefined>;
}

const CLOSE_TOOLTIP_DELAY = 250;
const OPEN_TOOLTIP_DELAY = 500;

const Tooltip: FC<ITooltipProps> = ({
  children,
  tooltip,
  TriggerComponent = TooltipTrigger,
  ContainerComponent = TooltipContainer,
  placement = 'top',
  trigger = 'hover',
  isShown = true,
  handleClose = safePolygon(),
  offsetOptions,
  whiteSpace,
  isShouldShowOnMobile = false,
  className,
  floatingPortalRoot = document.body,
  triggerComponentProps,
}) => {
  const [open, setOpen] = useState(false);

  const { x, y, refs, strategy, context } = useFloating({
    open,
    onOpenChange: state => {
      if (isShown && trigger !== 'manual') {
        setOpen(state);
      }
    },
    placement,
    whileElementsMounted: autoUpdate,
    middleware: [offset(offsetOptions || 8), shift()],
  });

  const hover = useHover(context, {
    move: false,
    enabled: trigger === 'hover',
    handleClose,
  });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const click = useClick(context, { enabled: trigger === 'click' });
  const { isMounted, status } = useTransitionStatus(context, {
    duration: {
      open: OPEN_TOOLTIP_DELAY,
      close: CLOSE_TOOLTIP_DELAY,
    },
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([focus, dismiss, hover, click]);

  useEffect(() => {
    if (trigger === 'manual') {
      setOpen(isShown);
    }
  }, [trigger, isShown]);

  return (
    <>
      <TriggerComponent
        className={className}
        ref={refs.setReference}
        {...getReferenceProps()}
        {...triggerComponentProps}>
        {children}
      </TriggerComponent>
      {isMounted && isShown && (
        <FloatingPortal root={floatingPortalRoot}>
          <ContainerComponent
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              whiteSpace,
            }}
            isShouldShowOnMobile={isShouldShowOnMobile}
            data-status={status}
            {...getFloatingProps()}>
            <div>{tooltip}</div>
            {trigger === 'click' && (
              <div>
                <CloseIcon onClick={() => setOpen(false)} size={{ width: 14, height: 14 }} />
              </div>
            )}
          </ContainerComponent>
        </FloatingPortal>
      )}
    </>
  );
};

export const TooltipTrigger = styled.span`
  cursor: pointer;
  display: inline-flex;
`;

export const TooltipContainer = styled.div<{ isShouldShowOnMobile?: boolean }>`
  display: flex;
  transition-property: opacity;
  direction: ltr;
  ${TooltipCommonStyles}

  @media ${props => props.theme.mediaQueries.tablet} {
    ${props => !props.isShouldShowOnMobile && 'display: none;'}
  }

  &[data-status='initial'] {
    opacity: 0;
  }
  &[data-status='open'] {
    transition-delay: ${CLOSE_TOOLTIP_DELAY}ms;
    transition-duration: ${CLOSE_TOOLTIP_DELAY}ms;
  }

  &[data-status='close'] {
    transition-duration: ${CLOSE_TOOLTIP_DELAY}ms;
    opacity: 0;
  }
`;

const CloseIcon = styled(CloseIconBase)`
  margin-left: 12px;
  cursor: pointer;
`;

export default Tooltip;
