import { ReportType, Statistics } from 'modules/dashboard/types';
import { SquareFeetEnum, SquareFeetType } from 'modules/repQuotes';
import MeasurementsReportAdapter from './MeasurementsReportAdapter';
import { toFixed } from 'utils';
import { StructureInformation } from 'modules/quickQuote';
import {
  formatRoofleStructureToCommon,
  isMeasurementReportSelectForTotalSqFt,
} from 'modules/global/prices/utils';
import { serializeStatisticsStructuresToRoofleStructures } from 'modules/repQuotes/utils';

const CUSTOM_SQFT_FIXED_TO = 3;

class TotalSquareFeetAdapter {
  private readonly statistics: Statistics;

  measurementOrders: ReportType[] = [];

  readonly defaultSquareFeetSettings: SquareFeetType = {
    type: SquareFeetEnum.InstantQuote,
    value: {},
  };

  constructor(information: Statistics, orders: ReportType[] = []) {
    this.statistics = information;
    this.measurementOrders = orders;
  }

  get squareFeetSettings(): SquareFeetType {
    const squareFeetSettings = this.statistics?.additionalInformation?.squareFeetSettings;

    if (!squareFeetSettings || squareFeetSettings.type === SquareFeetEnum.InstantQuote) {
      return this.defaultSquareFeetSettings;
    }

    if (squareFeetSettings.type === SquareFeetEnum.Custom) {
      return {
        type: SquareFeetEnum.Custom,
        value: this.statistics.additionalInformation.structures
          .filter(structure => structure.isIncluded)
          .reduce(
            (acc, structure) => ({
              ...acc,
              [structure.geoJsonPolygon?.id as string]: structure.customTotalSquareFeet,
            }),
            {},
          ),
      };
    }

    const selectedOrder = this.measurementOrders.find(
      order => order.id === squareFeetSettings.uniqId,
    );

    const structures = selectedOrder ? new MeasurementsReportAdapter(selectedOrder).structures : [];

    return {
      type: squareFeetSettings.type,
      uniqId: selectedOrder?.id,
      value: structures.reduce(
        (acc, structure) => ({ ...acc, [structure.id]: structure.squareFeet }),
        {},
      ),
    };
  }

  get structures(): StructureInformation[] {
    if (isMeasurementReportSelectForTotalSqFt(this.squareFeetSettings.type)) {
      const selectedOrder = this.measurementOrders.find(
        order => order.id === this.squareFeetSettings.uniqId,
      );

      return selectedOrder
        ? new MeasurementsReportAdapter(selectedOrder).structures.map(structure => {
            const savedReportWf =
              this.statistics.additionalInformation.thirdPartyReportWasteFactor?.reportWasteFactors.find(
                wf => wf.id === structure.id,
              )?.wasteFactor;

            return {
              ...structure,
              wasteFactor: {
                ...structure.wasteFactor,
                ...savedReportWf,
              },
            };
          })
        : [];
    }

    return serializeStatisticsStructuresToRoofleStructures(
      this.statistics.additionalInformation.structures,
    )
      .filter(structure => structure.isIncluded)
      .map(structure =>
        formatRoofleStructureToCommon(structure, {
          squareFeet:
            this.squareFeetSettings.type === SquareFeetEnum.Custom
              ? structure.customTotalSquareFeet
              : structure.measurements.squareFeet,

          wholeSquareFeet:
            this.squareFeetSettings.type === SquareFeetEnum.Custom
              ? structure.customTotalSquareFeet
              : structure.measurements.wholeSquareFeet,
        }),
      );
  }

  get totalSquareFeet(): number {
    if (this.squareFeetSettings.type === SquareFeetEnum.InstantQuote) {
      return this.statistics.totalSquareFeet;
    }

    return Object.values(this.squareFeetSettings.value).reduce(
      (sum, value) =>
        +toFixed(
          sum + value,
          this.squareFeetSettings.type === SquareFeetEnum.Custom ? CUSTOM_SQFT_FIXED_TO : 0,
        ),
      0,
    );
  }
}

export default TotalSquareFeetAdapter;
