import forEach from 'lodash/forEach';
import keyBy from 'lodash/keyBy';
import defaultsDeep from 'lodash/defaultsDeep';
import isEmpty from 'lodash/isEmpty';

import {
  CommonStructureInformation,
  RoofleStructure,
  StructureInformation,
} from 'modules/quickQuote/types';
import {
  AddAdditionalCostsFormKey,
  AddAdditionalCostsFormValues,
  AdditionalCostQuantityFormKey,
  FormattedQuoteSettings,
  OtherCost,
  QuoteSettingsModel,
} from 'modules/quoteSettings/types';
import {
  DifficultAccess,
  NewDecking,
  PricePerUnitOfMeasurement,
  PriceRangeObj,
  PriceRangeSettings,
  PriceRangeType,
  PriceType,
  Quantity,
  RoofCondition,
  RoofType,
} from 'modules/global/types';
import {
  DiscountModel,
  DiscountUnitOfMeasurement,
  QuickQuoteProductPriceSettings,
  SquareFeetEnum,
} from 'modules/repQuotes/types';
import { PriceInfo } from 'modules/financing';
import {
  areAdditionalCostsEnabledInModel,
  areAdditionalCostsInvalidForStructures,
} from 'modules/quoteSettings/components/utils';
import { toFixed } from 'utils';
import { pitchToSlopeName } from 'modules/quickQuote/utils';

interface AdditionalCostValuesByStructure {
  [key: number]: Record<string, number>;
}

const STANDARD_ROUNDING_NUMBER = 1200; // Default rounding number;
const STANDARD_MINIMAL_PRICE = 1;

export const getPaymentMultiplier = (interestRate: number, loanLengthInMonths: number) =>
  interestRate === 0
    ? 1 / loanLengthInMonths
    : interestRate /
      1200 /
      (1 - Math.pow(1 / (1 + interestRate / STANDARD_ROUNDING_NUMBER), loanLengthInMonths));

const addedPrice = ({
  type,
  value = 0,
  sqft = 1,
  quantity = 1,
}: {
  type: string;
  value: number;
  sqft: number;
  quantity?: number;
}): number => {
  if (type === PriceType.PerSquare) {
    return +toFixed(sqft * (value * quantity), 2);
  }

  // value stores price per square, we need to multiply to 100 to get fixed price
  return +toFixed(value * 100 * quantity, 2);
};

export const EXTRA_COST_KEY_PREFIX = 'question-';
export const EXTRA_COST_ANSWER_ID_PREFIX = 'answer-id-';

export const generateExtraCostKey = (id: number | string): string =>
  `${EXTRA_COST_KEY_PREFIX}${id}`;

export const generateExtraCostAnswerId = (id: number): string =>
  `${EXTRA_COST_ANSWER_ID_PREFIX}${id}`;

export const isExtraCostAnswerId = (id?: string): boolean =>
  !!id && id.includes(EXTRA_COST_ANSWER_ID_PREFIX);

export const generateRoofTypeAdditionalCostKey = roofType =>
  `${AddAdditionalCostsFormKey.RoofTypeCost}-${roofType}`;

export const isRoofTypeAdditionalCostKey = (key: string): boolean =>
  key.indexOf(AddAdditionalCostsFormKey.RoofTypeCost) === 0;

export const getRoofTypeFromRoofTypeAdditionalCostKey = (key: string): string =>
  key.split('-')?.[1] || '';

export const formatQuoteSettings = (
  settings: QuoteSettingsModel,
): Partial<FormattedQuoteSettings> => {
  const result: Partial<FormattedQuoteSettings> = {};

  if (settings.storyCostsEnabled) {
    result.storyCosts = {
      [Quantity.Two]: {
        type: settings.twoStoriesCostType,
        value: +settings.twoStoriesCost,
      },
      [Quantity.Many]: {
        type: settings.manyStoriesCostType,
        value: +settings.manyStoriesCost,
      },
    };
  }

  if (settings.layerCostsEnabled) {
    result.layerCosts = {
      [Quantity.Two]: {
        type: settings.twoLayersCostType,
        value: +settings.twoLayersCost,
      },
      [Quantity.Many]: {
        type: settings.manyLayersCostType,
        value: +settings.manyLayersCost,
      },
    };
  }

  if (settings.chimneyCostsEnabled) {
    result.chimneyFlashingCost = {
      [Quantity.One]: {
        type: PriceType.Fixed,
        value: +settings.chimneyFlashingCost,
      },
      [Quantity.Two]: {
        type: PriceType.Fixed,
        value: +settings.twoChimneysFlashingCost,
      },
      [Quantity.Many]: {
        type: PriceType.Fixed,
        value: +settings.manyChimneysFlashingCost,
      },
    };
  }

  if (settings.skylightCostsEnabled) {
    result.skylightFlashingCost = {
      [Quantity.One]: {
        type: PriceType.Fixed,
        value: +settings.skylightFlashingCost,
      },
      [Quantity.Two]: {
        type: PriceType.Fixed,
        value: +settings.twoSkylightsFlashingCost,
      },
      [Quantity.Many]: {
        type: PriceType.Fixed,
        value: +settings.manySkylightsFlashingCost,
      },
    };
  }

  if (settings.roofConditionCostsEnabled) {
    result.roofConditionCosts = {
      [RoofCondition.AlgaeStains]: {
        type: settings.algaeStainsCostType,
        value: +settings.algaeStainsCost,
      },
      [RoofCondition.HailDamage]: {
        type: settings.hailDamageCostType,
        value: +settings.hailDamageCost,
      },
      [RoofCondition.WindDamage]: {
        type: settings.windDamageCostType,
        value: +settings.windDamageCost,
      },
      [RoofCondition.MissingShingles]: {
        type: settings.missingShinglesCostType,
        value: +settings.missingShinglesCost,
      },
      [RoofCondition.RoofLeaks]: {
        type: settings.roofLeaksCostType,
        value: +settings.roofLeaksCost,
      },
      [RoofCondition.Sagging]: {
        type: settings.saggingCostType,
        value: +settings.saggingCost,
      },
      [RoofCondition.StructuralDamage]: {
        type: settings.structuralDamageCostType,
        value: +settings.structuralDamageCost,
      },
    };
  }

  if (settings.roofTypeCostsEnabled) {
    result.roofTypeCost = {
      [RoofType.AsphaltShingle]: {
        type: settings.asphaltShingleRoofCostType,
        value: +settings.asphaltShingleRoofCost,
      },
      [RoofType.Metal]: {
        type: settings.metalRoofCostType,
        value: +settings.metalRoofCost,
      },
      [RoofType.Synthetic]: {
        type: settings.syntheticRoofCostType,
        value: +settings.syntheticRoofCost,
      },
      [RoofType.Tile]: {
        type: settings.tileRoofCostType,
        value: +settings.tileRoofCost,
      },
      [RoofType.Wood]: {
        type: settings.woodRoofCostType,
        value: +settings.woodRoofCost,
      },
    };
  }

  if (settings.newDeckingCostsEnabled) {
    result.newDeckingCost = {
      [NewDecking.Partial]: {
        type: PriceType.Fixed,
        value: +settings.partialNewDeckingCost,
      },
      [NewDecking.FullDeckingReplacement]: {
        type: PriceType.PerSquare,
        value: +settings.fullDeckingReplacementCost,
      },
    };
  }

  if (settings.difficultAccessCostsEnabled) {
    result.difficultAccessCost = {
      [DifficultAccess.No]: {
        type: settings.difficultAccessCostType,
        value: +settings.difficultAccessCost,
      },
    };
  }

  if (settings.extraCostsEnabled) {
    result.otherExtraCosts = settings.extraCosts?.reduce(
      (acc: Record<string, Record<number, OtherCost>>, item) => {
        acc[generateExtraCostKey(item.id as number)] = item.answers.reduce(
          (res: Record<number, OtherCost>, answer) => ({
            ...res,
            [answer.id as number]: {
              value: +answer.cost,
              type: answer.costType || answer.unitOfMeasurement,
              name: answer.optionName,
              id: generateExtraCostAnswerId(answer.id as number),
            },
          }),
          {},
        );
        return acc;
      },
      {},
    );
  }

  return result;
};

export const addMergedOtherCostSettings = (
  additionalCostSettings1: Partial<FormattedQuoteSettings>,
  additionalCostSettings2: Partial<FormattedQuoteSettings>,
): Partial<FormattedQuoteSettings> => {
  const mergeResults: Partial<FormattedQuoteSettings> = {
    ...additionalCostSettings1,
  };

  const otherCosts = defaultsDeep(
    additionalCostSettings1[AddAdditionalCostsFormKey.OtherExtraCosts] || {},
    additionalCostSettings2[AddAdditionalCostsFormKey.OtherExtraCosts] || {},
  );

  if (!isEmpty(otherCosts)) {
    mergeResults[AddAdditionalCostsFormKey.OtherExtraCosts] = otherCosts;
  }

  return mergeResults;
};

export const prepareAdditionalCostsForMerging = (
  structures: StructureInformation[],
  additionalCost?: Record<string | number, AddAdditionalCostsFormValues>,
  additionalCostSettings?: QuoteSettingsModel,
): {
  additionalCostsByStructure: Record<string, Record<string, number>> | null;
  formattedAdditionalCostSettings: Partial<FormattedQuoteSettings> | null;
} => {
  if (
    !additionalCost ||
    !additionalCostSettings ||
    !areAdditionalCostsEnabledInModel(additionalCostSettings) ||
    areAdditionalCostsInvalidForStructures(additionalCost, structures)
  ) {
    return {
      additionalCostsByStructure: null,
      formattedAdditionalCostSettings: null,
    };
  }

  const formattedAdditionalCostSettings = formatQuoteSettings(additionalCostSettings);
  const addedValues = calculateStructuresAddedValues({
    structures,
    additionalCost,
    additionalCostSettings: formattedAdditionalCostSettings,
  });

  return {
    additionalCostsByStructure: addedValues,
    formattedAdditionalCostSettings: formattedAdditionalCostSettings,
  };
};

export const getOtherCostKeyNameMap = (
  formattedOtherCosts?: FormattedQuoteSettings[AddAdditionalCostsFormKey.OtherExtraCosts],
): Record<string, string> => {
  if (!formattedOtherCosts) {
    return {};
  }

  const result: Record<string, string> = {};
  forEach(formattedOtherCosts, answers => {
    forEach(answers, otherCost => {
      result[otherCost.id] = otherCost.name;
    });
  });
  return result;
};

const calculateRoofConditionCosts = (
  values: string[],
  structure: StructureInformation,
  settings: FormattedQuoteSettings[AddAdditionalCostsFormKey.RoofConditionCosts],
): Record<string, number> => {
  return values.reduce((acc, item) => {
    const roofSettings = settings[item];

    if (roofSettings) {
      acc[item] = addedPrice({
        ...roofSettings,
        sqft: structure.squareFeet,
      });
    }

    return acc;
  }, {});
};

const calculateRoofTypeCosts = (
  value: string,
  structure: StructureInformation,
  settings: FormattedQuoteSettings[AddAdditionalCostsFormKey.RoofTypeCost],
  structureAdditionalCost: AddAdditionalCostsFormValues,
): Record<string, number> => {
  const costSettings = settings[value];

  if (costSettings) {
    const roofType = structureAdditionalCost[AddAdditionalCostsFormKey.RoofTypeCost];
    const key = generateRoofTypeAdditionalCostKey(roofType);
    return {
      [key]: addedPrice({
        ...costSettings,
        quantity: 1,
        sqft: structure.squareFeet,
      }),
    };
  }

  return {};
};

const calculateOtherExtraCosts = (
  values: Record<string, number | number[]>,
  structure: StructureInformation,
  settings: FormattedQuoteSettings[AddAdditionalCostsFormKey.OtherExtraCosts],
  structureAdditionalCost: AddAdditionalCostsFormValues,
): Record<string, number> => {
  return Object.entries(values).reduce((acc, [itemKey, itemValue]) => {
    const extraSettings = settings[itemKey];
    const extraQuantitiesSettings =
      structureAdditionalCost[AdditionalCostQuantityFormKey.OtherExtraCostsQuantity];

    if (!extraSettings) {
      return acc;
    }

    const quantitySettings = extraQuantitiesSettings && extraQuantitiesSettings[itemKey];
    const valuesMap = [itemValue].flat();

    return Object.assign(
      acc,
      valuesMap.reduce((res: Record<string, number>, extraKey) => {
        if (!extraSettings[extraKey]) {
          return res;
        }

        const { name, id, ...rest } = extraSettings[extraKey];

        if (rest.type === PricePerUnitOfMeasurement.None) {
          return res;
        }

        let quantity;

        if (
          quantitySettings &&
          quantitySettings[extraKey] &&
          rest.type !== PriceType.PerSquare &&
          rest.type !== PriceType.Fixed
        ) {
          quantity = quantitySettings[extraKey];
        }

        return Object.assign(res, {
          [id]: addedPrice({
            ...rest,
            quantity,
            sqft: structure.squareFeet,
          }),
        });
      }, {}),
    );
  }, {});
};

const calculateNewDeckingCost = (
  value: string,
  structure: StructureInformation,
  settings: FormattedQuoteSettings[AddAdditionalCostsFormKey.NewDeckingCost],
  structureAdditionalCost: AddAdditionalCostsFormValues,
): Record<string, number> => {
  const costSettings = settings[value];
  const quantity =
    value === NewDecking.Partial && structureAdditionalCost.newDeckingQuantity
      ? +structureAdditionalCost.newDeckingQuantity
      : 1;

  if (costSettings) {
    return {
      [AddAdditionalCostsFormKey.NewDeckingCost]: addedPrice({
        ...costSettings,
        quantity,
        sqft: structure.squareFeet,
      }),
    };
  }

  return {};
};

const calculateStructureAdditionalCosts = (
  structureAdditionalCost: AddAdditionalCostsFormValues,
  structure: StructureInformation,
  additionalCostSettings: Partial<FormattedQuoteSettings>,
): Record<string, number> => {
  return Object.entries(structureAdditionalCost).reduce(
    (config: Record<string, number>, [key, value]) => {
      const settings = additionalCostSettings[key];

      if (!settings || !value) {
        return config;
      }

      if (key === AddAdditionalCostsFormKey.RoofConditionCosts) {
        return {
          ...config,
          ...calculateRoofConditionCosts(value, structure, settings),
        };
      }

      if (key === AddAdditionalCostsFormKey.RoofTypeCost) {
        return {
          ...config,
          ...calculateRoofTypeCosts(value, structure, settings, structureAdditionalCost),
        };
      }

      if (key === AddAdditionalCostsFormKey.OtherExtraCosts) {
        return {
          ...config,
          ...calculateOtherExtraCosts(value, structure, settings, structureAdditionalCost),
        };
      }

      if (key === AddAdditionalCostsFormKey.NewDeckingCost) {
        return {
          ...config,
          ...calculateNewDeckingCost(value, structure, settings, structureAdditionalCost),
        };
      }

      const costSettings = settings[value as string];

      if (costSettings) {
        return Object.assign(config, {
          [key]: addedPrice({
            ...costSettings,
            quantity: 1,
            sqft: structure.squareFeet,
          }),
        });
      }

      return config;
    },
    {},
  );
};

export const calculateStructuresAddedValues = ({
  structures,
  additionalCost,
  additionalCostSettings,
}: {
  structures: StructureInformation[];
  additionalCostSettings: Partial<FormattedQuoteSettings>;
} & Required<Pick<QuickQuoteProductPriceSettings, 'additionalCost'>>): Record<
  number,
  Record<string, number>
> => {
  if (isEmpty(structures)) {
    return {};
  }

  if (isEmpty(additionalCost) || isEmpty(additionalCostSettings)) {
    return structures.reduce((accum: Record<number, Record<string, number>>, structure) => {
      accum[structure.id] = {};
      return accum;
    }, {});
  }

  const prices = structures.reduce((accum: Record<number, Record<string, number>>, structure) => {
    const structureAdditionalCost = additionalCost && additionalCost[structure.id];

    accum[structure.id] = structureAdditionalCost
      ? calculateStructureAdditionalCosts(
          structureAdditionalCost,
          structure,
          additionalCostSettings,
        )
      : {};
    return accum;
  }, {});

  return prices;
};

export const combineAdditionalCostsByStructure = (
  additionalCostsByStructure: AdditionalCostValuesByStructure,
): Record<string, number> => {
  return Object.values(additionalCostsByStructure).reduce((acc, additionalCosts) => {
    Object.entries(additionalCosts).forEach(([key, value]) => {
      if (acc[key]) {
        acc[key] = +toFixed(acc[key] + value, 2);
        return;
      }

      acc[key] = value;
    });
    return acc;
  }, {});
};

export const computeDiscount = (value: number, discount: DiscountModel): number => {
  if (value === 0) {
    return value;
  }

  if (discount.unit === DiscountUnitOfMeasurement.FixedPrice) {
    return Math.min(value, +discount.value);
  }

  return +(value * (+discount.value / 100)).toFixed(2);
};

export const applyDiscount = (value: number, discount: DiscountModel): number => {
  if (value === 0) {
    return value;
  }

  if (discount.unit === DiscountUnitOfMeasurement.FixedPrice) {
    return Math.max(0, +(value - +discount.value).toFixed(2));
  }

  return +(value * (1 - +discount.value / 100)).toFixed(2);
};

export const applyAdditionalCostsDiscounts = (
  additionalCosts: Record<string, number>,
  discounts?: DiscountModel[],
): Record<string, number> => {
  if (!discounts?.length) {
    return additionalCosts;
  }

  const discountsMap = keyBy(discounts, 'type');
  return Object.entries(additionalCosts).reduce((acc, [key, value]) => {
    if (!discountsMap[key] || !value) {
      acc[key] = value;
      return acc;
    }

    acc[key] = applyDiscount(value, discountsMap[key]);
    return acc;
  }, {});
};

export const computeAdditionalCostsDiscounts = (
  additionalCosts: Record<string, number>,
  discounts?: DiscountModel[],
): Record<string, number> => {
  if (!discounts?.length) {
    return {};
  }

  const discountsMap = keyBy(discounts, 'type');
  return Object.entries(additionalCosts).reduce((acc, [key, value]) => {
    if (!discountsMap[key] || !value) {
      return acc;
    }

    acc[key] = computeDiscount(value, discountsMap[key]);
    return acc;
  }, {});
};

export const computeAdditionalCostsTotal = (
  additionalCosts: AdditionalCostValuesByStructure | null,
): number => {
  return additionalCosts
    ? Object.values(combineAdditionalCostsByStructure(additionalCosts)).reduce(
        (sum, value) => sum + value,
        0,
      )
    : 0;
};

export const getPriceRange = ({
  priceRangeSettings,
  priceInfo,
  showPriceRange,
}: {
  priceRangeSettings: PriceRangeSettings | null;
  priceInfo: PriceInfo;
  showPriceRange: boolean;
}): PriceRangeObj => {
  if (!priceRangeSettings || !priceInfo.total) {
    return {
      showPriceRange: false,
      totalMin: priceInfo.total,
      totalMax: priceInfo.total,
      monthlyMin: priceInfo.monthly,
      monthlyMax: priceInfo.monthly,
    };
  }

  const { value: priceRangeValue } = priceRangeSettings;
  const priceRange = priceRangeValue / 100;

  switch (priceRangeSettings.type) {
    case PriceRangeType.Full: {
      return {
        showPriceRange,
        totalMin: Math.floor(priceInfo.total * (1 - priceRange)) || STANDARD_MINIMAL_PRICE,
        totalMax: Math.floor(priceInfo.total * (1 + priceRange)) || STANDARD_MINIMAL_PRICE,
        monthlyMin:
          priceInfo.monthly !== undefined
            ? Math.floor(priceInfo.monthly * (1 - priceRange)) || STANDARD_MINIMAL_PRICE
            : undefined,
        monthlyMax:
          priceInfo.monthly !== undefined
            ? Math.floor(priceInfo.monthly * (1 + priceRange)) || STANDARD_MINIMAL_PRICE
            : undefined,
      };
    }
    case PriceRangeType.Above: {
      return {
        showPriceRange,
        totalMin: Math.floor(priceInfo.total) || STANDARD_MINIMAL_PRICE,
        totalMax: Math.floor(priceInfo.total * (1 + priceRange)) || STANDARD_MINIMAL_PRICE,
        monthlyMin:
          priceInfo.monthly !== undefined ? priceInfo.monthly || STANDARD_MINIMAL_PRICE : undefined,
        monthlyMax:
          priceInfo.monthly !== undefined
            ? Math.floor(priceInfo.monthly * (1 + priceRange)) || STANDARD_MINIMAL_PRICE
            : undefined,
      };
    }
    case PriceRangeType.Below: {
      return {
        showPriceRange,
        totalMin: Math.floor(priceInfo.total * (1 - priceRange)) || STANDARD_MINIMAL_PRICE,
        totalMax: Math.floor(priceInfo.total) || STANDARD_MINIMAL_PRICE,
        monthlyMin:
          priceInfo.monthly !== undefined
            ? Math.floor(priceInfo.monthly * (1 - priceRange)) || STANDARD_MINIMAL_PRICE
            : undefined,
        monthlyMax:
          priceInfo.monthly !== undefined ? priceInfo.monthly || STANDARD_MINIMAL_PRICE : undefined,
      };
    }
    default: {
      return {
        showPriceRange,
        totalMin: priceInfo.total,
        totalMax: priceInfo.total,
        monthlyMin: priceInfo.monthly,
        monthlyMax: priceInfo.monthly,
      };
    }
  }
};

export const formatRoofleStructureToCommon = (
  structure: RoofleStructure,
  { squareFeet, wholeSquareFeet }: { squareFeet?: number; wholeSquareFeet?: number } = {},
): CommonStructureInformation => ({
  id: structure.geoJsonPolygon.id as string,
  slope: pitchToSlopeName(structure.geoJsonPolygon.properties?.slope || structure.slope),
  name: structure.name,
  squareFeet: squareFeet || structure.measurements.squareFeet,
  wholeSquareFeet: wholeSquareFeet || structure.measurements.wholeSquareFeet,
  wasteFactor: structure.wasteFactor,
  isIncluded: !!structure.isIncluded,
  initialSquareFeet: structure.measurements.initialSquareFeet,
  roofComplexity: structure.roofComplexity,
});

export const isMeasurementReportSelectForTotalSqFt = (
  type: SquareFeetEnum = SquareFeetEnum.InstantQuote,
): boolean => ![SquareFeetEnum.InstantQuote, SquareFeetEnum.Custom].includes(type);

export const formatMerchantFeeValue = (value: number | string): number => +toFixed(+value / 100, 4);
