import { EagleviewOrder, GAFQuickMeasure, MeasurementsReportType } from 'modules/dashboard';
import { ReportTypeToTitleMap } from 'modules/orders/constants';
import {
  mapFromFullWasteFactorToCoefficient,
  mapWasteFactor,
} from 'modules/quoteSettings/serializers';

import {
  ReportStructureInformation,
  StructureSlope,
  WasteFactorTypeEnum,
} from 'modules/quickQuote/types';

const GAFLogoPath = 'cms/GAF_logo.png';
const EagleViewLogoPath = 'cms/eagleview_logo.png';

const DEFAULT_GAF_WF = 110;

class MeasurementsReportAdapter {
  constructor(private target: GAFQuickMeasure | EagleviewOrder) {}

  get report(): GAFQuickMeasure | EagleviewOrder {
    return this.target;
  }

  get type(): MeasurementsReportType {
    return 'type' in this.target ? this.target.type : MeasurementsReportType.GAFMeasurements;
  }

  get title(): string {
    return ReportTypeToTitleMap[this.type];
  }

  get updatedAt(): string {
    return this.target.updatedAt;
  }

  get logo(): string {
    return this.type === MeasurementsReportType.GAFMeasurements ? GAFLogoPath : EagleViewLogoPath;
  }

  get structures(): ReportStructureInformation[] {
    if (this.type === MeasurementsReportType.GAFMeasurements) {
      const target = this.target as GAFQuickMeasure;
      const structures = target.buildings.length
        ? target.buildings
        : [{ ...target, suggestedWaste: DEFAULT_GAF_WF }];

      return structures.map((item, index) => ({
        name: `Structure ${index + 1}`,
        id: `${this.type}-${this.target.id}-${index}`.toLowerCase(),
        squareFeet: item.area,
        slope: `${item.pitch}/12` as StructureSlope,
        wasteFactor: {
          suggested: mapFromFullWasteFactorToCoefficient(item.suggestedWaste),
          usedWFType: WasteFactorTypeEnum.Suggested,
        },
      }));
    } else {
      const target = this.target as EagleviewOrder;
      const structures = target.structures || target.report?.measurementByStructure || [];
      const suggestedWasteFactors = target.wasteFactorTable || target.report?.structureWasteTables;

      return structures.map((item, index) => {
        const suggestedWasteFactor = suggestedWasteFactors?.[
          index
        ].structureWasteTable[0][3].replace('%', '');

        return {
          name: item.buildingName,
          id: `${this.type}-${this.target.id}-${index}`.toLowerCase(),
          squareFeet: item.areaValue,
          slope: item.primaryPitch as StructureSlope,
          wasteFactor: {
            suggested: (suggestedWasteFactor && mapWasteFactor(suggestedWasteFactor)) || 1,
            usedWFType: WasteFactorTypeEnum.Suggested,
          },
        };
      });
    }
  }

  get totalSqFt(): number {
    return 'area' in this.target
      ? this.target.area
      : parseFloat(this.target.report?.area as string);
  }

  get hips(): number {
    return 'hips' in this.target
      ? this.target.hips
      : parseFloat(this.target.report?.lengthHip || '');
  }

  get ridges(): number {
    return 'ridges' in this.target
      ? this.target.ridges
      : parseFloat(this.target.report?.lengthRidge || '');
  }

  get eaves(): number {
    return 'eaves' in this.target
      ? this.target.eaves
      : parseFloat(this.target.report?.lengthEave || '');
  }

  get rakes(): number {
    return 'rakes' in this.target
      ? this.target.rakes
      : parseFloat(this.target.report?.lengthRake || '');
  }

  get ridgeCap(): number | undefined {
    return 'ridgeCap' in this.target ? this.target.ridgeCap : undefined;
  }

  get valleys(): number {
    return 'valleys' in this.target
      ? this.target.valleys
      : parseFloat(this.target.report?.lengthValley || '');
  }

  get starter(): number | undefined {
    return 'starter' in this.target ? this.target.starter : undefined;
  }

  get flashing(): number | undefined {
    return 'flash' in this.target ? this.target.flash : undefined;
  }

  get facets(): number {
    return 'facets' in this.target
      ? this.target.facets
      : parseFloat(this.target.report?.totalRoofFacets || '');
  }

  get pitch(): string | undefined {
    return 'pitch' in this.target ? this.target.pitch : this.target.report?.pitch;
  }

  get parapets(): number | undefined {
    return 'parapets' in this.target ? this.target.parapets : undefined;
  }

  get wasteFactor(): string | number | undefined {
    if (this.type === MeasurementsReportType.GAFMeasurements) {
      return (this.target as GAFQuickMeasure).buildings.reduce(
        (sum, item) => sum + item.suggestedWaste,
        0,
      );
    }
    const target = this.target as EagleviewOrder;
    const wasteFactorTable = target.wasteFactorTable || target.report?.structureWasteTables;

    return wasteFactorTable?.[0].structureWasteTable[0][2];
  }

  get dripEdge(): number | undefined {
    return 'dripEdge' in this.target ? this.target.dripEdge : undefined;
  }
}

export default MeasurementsReportAdapter;
