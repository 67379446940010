import {
  Query,
  StatisticsStatus,
  MeasureOrderStatus,
  MeasurementsStructures,
  DeliveryTime,
  ProductCode,
  LeadDetailsTabKeys,
  MeasurementsReportType,
  BuildingImageTagType,
} from 'modules/dashboard/types';
import { QuoteCreatingSourceRecord, QuoteCreatingSourceRecordType } from 'modules/repQuotes/types';
import { PriceRangeType } from 'modules/global/types';
import { initialQuery as _initialQuery } from 'components/Table';
import { ExportType, I18nEnum } from 'types';
import * as dashboardConstants from 'modules/dashboard/constants';

const name = 'dashboard/';

export const SET_STATISTICS = `${name}SET_STATISTICS`;
export const SET_POTENTIAL_CONTACTS_STATISTICS = `${name}SET_POTENTIAL_CONTACTS_STATISTICS`;
export const SET_ADDRESSES_ONLY = `${name}SET_ADDRESSES_ONLY`;
export const CLEAR_DASHBOARD = `${name}CLEAR_DASHBOARD`;
export const SET_STATISTICS_MEASUREMENTS = `${name}SET_STATISTICS_MEASUREMENTS`;

export const REPORT_FOR_STATISTICS_KEY = `${name}REPORT_FOR_STATISTICS`;
export const EAGLEVIEW_ORDERS_KEY = `${name}EAGLEVIEW_ORDERS`;
export const MEASUREMENTS_AVAILABLE_SERVICES_KEY = `${name}MEASUREMENTS_AVAILABLE_SERVICES`;
export const MEASUREMENTS_DOWNLOAD_REPORT_FILE_KEY = `${name}MEASUREMENTS_DOWNLOAD_REPORT_FILE`;
export const MEASUREMENTS_REPORTS_FOR_STATISTICS_KEY = `${name}MEASUREMENTS_REPORTS_FOR_STATISTICS`;
export const MEASUREMENTS_LAST_ORDER_FOR_STATISTICS_KEY = `${name}MEASUREMENTS_LAST_ORDER_FOR_STATISTICS`;
export const GAF_QUICK_MEASURE_SITE_STATUS_KEY = `${name}GAF_QUICK_MEASURE_SITE_STATUS`;
export const STATISTICS_KEY = `${name}STATISTICS`;
export const STATISTICS_BY_ID_KEY = `${name}STATISTICS_BY_ID`;
export const STATISTICS_FILTERS_OPTIONS_KEY = `${name}STATISTICS_FILTERS_OPTIONS`;
export const LATEST_REP_LEAD_KEY = `${name}LATEST_REP_LEAD`;
export const ORDER_FOR_STATISTICS_KEY = `${name}ORDER_FOR_STATISTICS`;
export const PRICE_RANGE_SETTING_KEY = `${name}PRICE_RANGE_SETTING`;
export const QUOTES_FOR_STATISTICS_KEY = `${name}QUOTES_FOR_STATISTICS`;
export const IMPORTED_CONTACTS_KEY = `${name}IMPORTED_CONTACTS`;
export const IMPORTED_CONTACTS_FILTERS_KEY = `${name}IMPORTED_CONTACTS_FILTERS`;
export const STATISTICS_NOTES_KEY = `${name}STATISTICS_NOTES`;
export const STATISTICS_MAP_SCREENSHOT_KEY = `${name}STATISTICS_MAP_SCREENSHOT_KEY`;

export const initialQuery: Query = { ..._initialQuery, filter: {} };

export const selectionMenuColumnClass = 'selection-menu-column';
export const createQuoteClass = 'create-quote-column';

export const CONTACTS_REPORTING_ROOT_ELEMENT_ID = 'contractor-reporting';

export const leadStatusColors = {
  [StatisticsStatus.new]: { textColor: '#646464', selectColor: '#EDEDED' },
  [StatisticsStatus.open]: { textColor: '#EEA834', selectColor: '#FCEED6' },
  [StatisticsStatus.cold]: { textColor: '#1565C0', selectColor: '#D0E0F2' },
  [StatisticsStatus.lost]: { textColor: '#E71616', selectColor: '#FAD0D0' },
  [StatisticsStatus.sold]: { textColor: '#5FAD56', selectColor: '#DFEFDD' },
  [StatisticsStatus.deleted]: { textColor: '#244C5A', selectColor: '#DEE4E6' },
};

export const leadStatuses = [
  StatisticsStatus.new,
  StatisticsStatus.open,
  StatisticsStatus.cold,
  StatisticsStatus.lost,
  StatisticsStatus.sold,
  StatisticsStatus.deleted,
];

export const exportTitles = {
  [ExportType.csv]: I18nEnum.ExportToCSV,
  [ExportType.excel]: I18nEnum.ExportToExcel,
};

export const measurementStatusColors = {
  [MeasureOrderStatus.inProgress]: { textColor: '#646464', backgroundColor: '#EDEDED' },
  [MeasureOrderStatus.declined]: { textColor: '#E71616', backgroundColor: '#FAD0D0' },
  [MeasureOrderStatus.pending]: { textColor: '#EF7E45', backgroundColor: '#FCE5DA' },
  [MeasureOrderStatus.completed]: { textColor: '#5FAD56', backgroundColor: '#DFEFDD' },
  [MeasureOrderStatus.failed]: { textColor: '#E71616', backgroundColor: '#FAD0D0' },
};

export const measurementStatusLabel = {
  [MeasureOrderStatus.inProgress]: I18nEnum.InProgress,
  [MeasureOrderStatus.declined]: I18nEnum.Declined,
  [MeasureOrderStatus.pending]: I18nEnum.Pending,
  [MeasureOrderStatus.completed]: I18nEnum.Completed,
  [MeasureOrderStatus.failed]: I18nEnum.Failed,
};

export const MEASUREMENTS_CHANNEL = 'MEASUREMENTS_CHANNEL';

export const gafStructuresOptions = [
  {
    label: I18nEnum.AllStructures,
    value: MeasurementsStructures.All,
  },
  {
    label: I18nEnum.MainRoofOnly,
    value: MeasurementsStructures.MainRoofOnly,
  },
  {
    label: I18nEnum.MainRoofPlus,
    value: MeasurementsStructures.MainRoofPlus,
  },
  {
    label: I18nEnum.OtherStructures,
    value: MeasurementsStructures.Other,
  },
];

export const eagleviewStructuresOptions = [
  ...gafStructuresOptions.slice(0, 3),
  {
    label: I18nEnum.CommercialComplex,
    value: MeasurementsStructures.CommercialComplex,
  },
  ...gafStructuresOptions.slice(3),
];
export const eagleviewDeliveryTimeOptions = [
  {
    label: I18nEnum.RegularDelivery,
    value: DeliveryTime.RegularDelivery,
  },
  {
    label: I18nEnum.ExpressDelivery,
    value: DeliveryTime.ExpressDelivery,
  },
  {
    label: I18nEnum.ThreeHourDelivery,
    value: DeliveryTime.ThreeHourDelivery,
  },
];

export const gafQuickMeasureProductCodeCredits = {
  [ProductCode.SF]: 1,
  [ProductCode.MF]: 3,
  [ProductCode.CM]: 3,
};

export const deliveryTimeToMessageMap = {
  [DeliveryTime.RegularDelivery]: I18nEnum.DefaultTurnaroundTimeOf3BusinessDays,
  [DeliveryTime.ExpressDelivery]: I18nEnum.DefaultTurnaroundTimeOf1BusinessDay,
  [DeliveryTime.ThreeHourDelivery]: I18nEnum.DefaultTurnaroundTimeOf3BusinessHours,
};

export const measurementReportTabsMap = {
  [MeasurementsReportType.GAFMeasurements]: LeadDetailsTabKeys.GAFQuickMeasure,
  [MeasurementsReportType.EagleViewBidPerfect]: LeadDetailsTabKeys.EagleviewBidPerfect,
  [MeasurementsReportType.EagleViewPremiumCommercial]:
    LeadDetailsTabKeys.EagleviewPremiumCommercial,
  [MeasurementsReportType.EagleViewPremiumResidential]:
    LeadDetailsTabKeys.EagleviewPremiumResidential,
};

export const dashboardQueriesKeys = [
  REPORT_FOR_STATISTICS_KEY,
  EAGLEVIEW_ORDERS_KEY,
  MEASUREMENTS_AVAILABLE_SERVICES_KEY,
  MEASUREMENTS_DOWNLOAD_REPORT_FILE_KEY,
  MEASUREMENTS_REPORTS_FOR_STATISTICS_KEY,
  MEASUREMENTS_LAST_ORDER_FOR_STATISTICS_KEY,
  STATISTICS_KEY,
  STATISTICS_BY_ID_KEY,
  STATISTICS_FILTERS_OPTIONS_KEY,
  ORDER_FOR_STATISTICS_KEY,
];

export const QUOTE_KEY = {
  custom: 'Custom',
  original: 'Original',
};

export const DEFAULT_PRICE_RANGE = {
  value: 10,
  type: PriceRangeType.Full,
};

export const buildingImageLabelsMap = {
  [BuildingImageTagType.North]: I18nEnum.North,
  [BuildingImageTagType.South]: I18nEnum.South,
  [BuildingImageTagType.East]: I18nEnum.East,
  [BuildingImageTagType.West]: I18nEnum.West,
};

export const quoteSourceToQueryKeyMap: Record<QuoteCreatingSourceRecordType, string> = {
  [QuoteCreatingSourceRecord.potentialContactsReporting]: dashboardConstants.STATISTICS_KEY,
  [QuoteCreatingSourceRecord.addressesOnlyReporting]: dashboardConstants.STATISTICS_KEY,
  [QuoteCreatingSourceRecord.importedContacts]: dashboardConstants.IMPORTED_CONTACTS_KEY,
};

export const IMPORT_CONTACT_FILE_NAME_ERROR_CODE = 409;
export const IMPORT_CONTACT_INVALID_COLUMNS_ERROR_CODE = 422;
