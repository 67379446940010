import memoize from 'lodash/memoize';

import { FixedPrice } from './fixedPrice';
import { PriceConfig } from './priceConfig';
import { SteepSlope } from './steepSlope';
import { WasteFactor } from './wasteFactor';

import { CommonStructureInformation } from 'modules/quickQuote/types';
import { calculateRegionalProductPricePerSquareFoot } from 'modules/regional/utils';
import { toFixed } from 'utils';

export class TotalPrice {
  constructor({
    priceConfig,
    steepSlope,
    fixedPrice,
    wasteFactor,
  }: {
    fixedPrice: FixedPrice;
    priceConfig: PriceConfig;
    steepSlope: SteepSlope;
    wasteFactor: WasteFactor;
  }) {
    this.priceConfig = priceConfig;
    this.steepSlopeInstance = steepSlope;
    this.fixedPriceInstance = fixedPrice;
    this.wasteFactorInstance = wasteFactor;
  }

  private readonly fixedPriceInstance: FixedPrice;

  private readonly priceConfig: PriceConfig;

  private readonly steepSlopeInstance: SteepSlope;

  private readonly wasteFactorInstance: WasteFactor;

  get retailSqftPrice(): number {
    if (!this.priceConfig.squareFeetPrice) {
      return 0;
    }

    const regionalMultiplier = memoize(calculateRegionalProductPricePerSquareFoot)({
      productSqftPrice: this.priceConfig.squareFeetPrice,
      regionalPricePerSquare: 397, // TODO: update this
    });

    return regionalMultiplier;
  }

  calculateStructuresPrices(): number[] {
    return this.priceConfig.includedStructures.map(structure => {
      const price = this.calculatePriceWithInstantQuoteCosts(structure);

      return +toFixed(Number(price), 2);
    });
  }

  calculatePriceWithInstantQuoteCosts(structure: CommonStructureInformation): number {
    const steepSlopeByStructures = this.steepSlopeInstance.steepSlopeByStructures;
    const wasteFactorByStructures = this.wasteFactorInstance.wasteFactorByStructures;

    const steepSlopeSqFt = steepSlopeByStructures?.[structure.id]?.steepSlopeSqFt || 0;
    const steepSlopeFixed = steepSlopeByStructures?.[structure.id]?.steepSlopeFixed || 0;
    const wasteFactorValue = wasteFactorByStructures?.[structure.id] || 1;

    //we should use or steepSlopeSqFt or steepSlopeFixed, but in this formula one of two numbers is 0 (0 doesn't affect on result)
    //it depend on steepSlopeChargeType. both functions check this condition
    return (
      structure.squareFeet * wasteFactorValue * (this.retailSqftPrice + steepSlopeSqFt) +
      steepSlopeFixed
    );
  }

  calculateTotalPrice(): number {
    const { isFixedPrice } = this.priceConfig;

    if (!isFixedPrice) {
      const price = this.calculateStructuresPrices().reduce(
        (accumulator, current) => accumulator + current,
        0,
      );

      return +toFixed(price, 2);
    }

    return this.fixedPriceInstance.calculateFixedPriceWithInstantQuoteCosts();
  }
}
