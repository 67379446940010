import {
  STORAGE_PAST_QUICK_QUOTE_MAPS_ALIAS,
  STORAGE_QUICK_QUOTE,
  STORAGE_QUICK_QUOTE_MAP_ALIAS,
  STORAGE_QUICK_QUOTE_MAP_DEMO_ALIAS,
} from './constants';
import { PastAddressesState, QuickQuoteState } from './types';
import { encryptQQ, decryptQQ } from './_utils';
import trackError from 'services/trackError';
import IDB from 'services/idb';
import store from 'store';
import { selectWidget } from 'modules/widget/selectors';
import {
  STORAGE_ANALYTICS,
  STORAGE_STATISTICS_ID_ALIAS,
  STORAGE_STATISTICS_ID_EXPIRATION_DATE_ALIAS,
} from 'modules/global/constants';

export function clearQuickQuoteStorage() {
  IDB.deleteItem(STORAGE_QUICK_QUOTE, STORAGE_QUICK_QUOTE_MAP_ALIAS);
}

export async function getQuickQuoteStorage() {
  const state = store.getState();
  const { demo } = selectWidget(state);

  const storage = await IDB.getItem(
    STORAGE_QUICK_QUOTE,
    demo ? STORAGE_QUICK_QUOTE_MAP_DEMO_ALIAS : STORAGE_QUICK_QUOTE_MAP_ALIAS,
  );

  if (!storage) {
    return null;
  }

  let quickQuoteStorage: QuickQuoteState;

  try {
    quickQuoteStorage = await decryptQQ(storage);
  } catch (error) {
    trackError(`decrypt error`, error);
    clearQuickQuoteStorage();

    return null;
  }

  return quickQuoteStorage;
}

export async function setQuickQuoteStorage(quickQuote: QuickQuoteState) {
  const state = store.getState();
  const { demo } = selectWidget(state);

  const qq = JSON.stringify(quickQuote);
  try {
    IDB.setItem(
      STORAGE_QUICK_QUOTE,
      demo ? STORAGE_QUICK_QUOTE_MAP_DEMO_ALIAS : STORAGE_QUICK_QUOTE_MAP_ALIAS,
      await encryptQQ(qq),
    );
  } catch (error) {
    trackError(`encrypt error`, error);
  }
}

// todo: past addresses aren't be used, is this from roofle.com?
export async function getPastAddresses(): Promise<PastAddressesState[]> {
  const pastAddresses: {
    address: string;
    quickQuote: string;
    addressFeature: string;
  }[] = await IDB.getItem(STORAGE_QUICK_QUOTE, STORAGE_PAST_QUICK_QUOTE_MAPS_ALIAS);

  const states: PastAddressesState[] = [];

  if (pastAddresses) {
    try {
      for (let index = 0; index < pastAddresses.length; index++) {
        const quickQuote = await decryptQQ(pastAddresses[index].quickQuote);
        const addressFeature = JSON.parse(pastAddresses[index].addressFeature);

        states[index] = { ...pastAddresses[index], search: { addressFeature }, quickQuote };
      }

      return states;
    } catch (error) {
      trackError(`decrypt error`, error);

      return [];
    }
  }

  return [];
}
