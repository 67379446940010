import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { formatPhone, getParamsFromUrl } from 'utils';
import { AddressFeature, street, city, state, postalCode } from 'types';
import { QuickQuoteUserInfo } from 'modules/quickQuote';
import useFetchAddress from 'modules/quickQuote/hooks/useFetchAddress';

export const salesRabbitParam = 'sr' as const;
export const leadIdParam = 'leadId' as const;

const firstName = 'firstName' as const;
const lastName = 'lastName' as const;
const phone = 'phone' as const;
const email = 'email' as const;

const buildObjectFromParameterList = (params: { [k: string]: string }) => (obj, param) => {
  const queryParameter = params[param];

  if (!queryParameter) {
    return obj;
  }

  obj[param] = queryParameter;
  if (param === phone) {
    obj[param] = formatPhone(queryParameter);
  }

  return obj;
};

export const getAlmostDoneFields = (externalUrl?: string): Partial<QuickQuoteUserInfo> => {
  const params = getParamsFromUrl(externalUrl);
  const almostDoneFields = [firstName, lastName, phone, email];

  const almostDone = almostDoneFields.reduce(
    buildObjectFromParameterList(params),
    {} as QuickQuoteUserInfo,
  );

  return almostDone;
};

const getAddressFields = (params: { [k: string]: string }): null | AddressFeature => {
  const addressFields = [street, city, state, postalCode];

  const addressFeature = addressFields.reduce(
    buildObjectFromParameterList(params),
    {} as AddressFeature,
  );

  return Object.keys(addressFeature).length === addressFields.length ? addressFeature : null;
};

export const getSalesRabbitParams = (externalUrl?: string) => {
  const params = getParamsFromUrl(externalUrl);
  return { salesRabbitParam: params[salesRabbitParam], leadIdParam: params[leadIdParam] };
};

export const getSalesEmailParam = (externalUrl?: string) => {
  const params = getParamsFromUrl(externalUrl);
  return params.rep;
};

export const getDataFromUrl = (externalUrl?: string) => {
  if (!externalUrl) {
    return;
  }
  const params = getParamsFromUrl(externalUrl);
  const almostDone = getAlmostDoneFields(externalUrl);
  const addressFeature = getAddressFields(params);

  return {
    almostDone,
    addressFeature,
    isSalesRabbitUrl: !!(addressFeature && params[salesRabbitParam] && params[leadIdParam]),
  };
};

const useQuickQuoteQueryParams = (externalUrl?: string) => {
  const dispatch = useDispatch();
  const [addressFeature, setAddressFeature] = useState<null | AddressFeature>(null);
  const [isSalesRabbitUrl, setIsSalesRabbitUrl] = useState<boolean | undefined>();

  const fetchAddress = useFetchAddress();

  useEffect(() => {
    const res = getDataFromUrl(externalUrl);
    if (res) {
      setAddressFeature(res.addressFeature);
      setIsSalesRabbitUrl(res.isSalesRabbitUrl);
    }
  }, [externalUrl]);

  useEffect(() => {
    if (!addressFeature) {
      return;
    }

    fetchAddress(addressFeature);
  }, [dispatch, fetchAddress, addressFeature]);

  return isSalesRabbitUrl;
};

export default useQuickQuoteQueryParams;
