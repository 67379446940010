import { ReportType } from 'modules/dashboard/types';
import { Line, ProductMerchantFeeType } from 'modules/product/types';

export enum AnalyticsEvent {
  qqOpened = 'Roof-quote-pro-viewed',
  contactFormOpened = 'Contact-form-displayed',
  getHelpFormOpened = 'Get-help-form-displayed',
  addRoofClicked = 'Add-roof-clicked',
  editRoofClicked = 'Edit-roofs-clicked',
  slopeChanged = 'Slope-changed',
  includeStructureToggled = 'Roof-inclusion-toggled',
  sendDetailsClicked = 'Product-requested-cta',
  addressEntered = 'Address-loaded',
  roofRenamed = 'Roof-renamed',
  contactFormSubmitted = 'Contact-form-submitted',
  getHelpFormSubmitted = 'Get-help-form-submitted',
  invitationFormOpened = 'Invitation-form-displayed',
  invitationFormSubmitted = 'Invitation-form-submitted',
  howInstanLoanWOrksOpened = 'How-Instant-Loan-Works-Opened',
  customButtonClicked = 'Custom-button-clicked',
  sessionIdChanged = 'Session-Id-Changed',
}

export interface WidgetAction {
  event: AnalyticsEvent;
  meta?: string;
  isSalesRabbitUrl?: boolean;
}

// Prices types

export interface PricesParams {
  squareFeetPrice: number;
  fixedPrice: boolean;
  state?: any;
  selectQQFinancing?: boolean;
  unavailableStates?: string[];
  line?: Line;
  orders?: ReportType[];
}

export interface PriceRangeObj {
  showPriceRange: boolean;
  totalMin: number;
  totalMax: number;
  monthlyMin?: number;
  monthlyMax?: number;
}

export enum PriceType {
  Fixed = 'fixed',
  PerSquare = 'perSquare',
}

export enum PricePerUnitOfMeasurement {
  None = 'none',
  Fixed = 'fixed',
  PerSquare = 'perSquare',
  PerLinealFeet = 'perLinealFeet',
  PerEach = 'perEach',
  PerBundle = 'perBundle',
  PerPiece = 'perPiece',
  PerRoll = 'perRoll',
  PerCarton = 'perCarton',
  PerTube = 'perTube',
  PerCan = 'perCan',
  PerCase = 'perCase',
  Custom = 'custom',
}

export enum PriceRangeType {
  Above = 'above',
  Below = 'below',
  Full = 'full',
}

export interface PriceRangeSettings {
  value: number;
  type: PriceRangeType;
}

export enum Quantity {
  None = 'none',
  NotApplicable = 'na',
  One = 'one',
  Two = 'two',
  Many = 'many',
}

export enum RoofCondition {
  None = 'none',
  Sagging = 'sagging',
  AlgaeStains = 'algaeStains',
  StructuralDamage = 'structuralDamage',
  HailDamage = 'hailDamage',
  WindDamage = 'windDamage',
  MissingShingles = 'missingShingles',
  RoofLeaks = 'roofLeaks',
}

export enum RoofType {
  AsphaltShingle = 'asphaltShingle',
  Metal = 'metal',
  Synthetic = 'synthetic',
  Tile = 'tile',
  Wood = 'wood',
}

export enum NewDecking {
  No = 'no',
  Partial = 'partial',
  FullDeckingReplacement = 'fullDeckingReplacement',
}

export enum DifficultAccess {
  NotApplicable = 'na',
  Yes = 'yes',
  No = 'no',
}

export enum PriceInfoType {
  // Default type includes all changes
  Default = 'Default',
  // Instant quote type includes all changes except squareFeet
  InstantQuote = 'InstantQuote',
  // Original type includes only base settings
  Original = 'Original',
}

export type SteepSlopeByStructures = Record<
  string,
  {
    steepSlopeSqFt: number;
    steepSlopeFixed: number;
  }
>;

export type WasteFactorByStructures = Record<string, number>;

export interface MerchantFeeValues {
  [ProductMerchantFeeType.Min]: number;
  [ProductMerchantFeeType.Average]: number;
  [ProductMerchantFeeType.Max]: number;
}
