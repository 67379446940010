import { Feature, Point } from 'geojson';
import { Properties } from '@turf/helpers';

import { User } from 'modules/auth/types';
import { ListQuery } from 'types';
import { PriceInfo } from 'modules/financing/types';
import { GeoJsonPolygon, MLWasteFactor, WasteFactorTypeEnum } from 'modules/quickQuote/types';
import { OptionType } from 'components/Inputs/Select';
import { AddAdditionalCostsFormValues, QuoteSettingsModel } from 'modules/quoteSettings/types';
import {
  DiscountModel,
  DiscountSettings,
  QuoteCreatingData,
  SquareFeetType,
  StructureWasteFactorData,
} from 'modules/repQuotes/types';
import { CustomProduct, DBProduct } from 'modules/product/types';
import { MeasurementsReportType, DeliveryTimeType } from './toolScript';
import { FormDragAndDropValue } from 'components/Upload/types';

export * from './toolScript';

export type StatisticsParams<Type> = ListQuery & { type: Type };

export type StatisticsDataReturnType = {
  [StatisticsType.ContactsReporting]: StatisticsListItem;
  [StatisticsType.PotentialContactsReporting]: PotentialContactsStatistics;
  [StatisticsType.AddressesOnlyReporting]: AddressOnly;
};

export interface Statistics {
  id: number;
  address: string;
  additionalInformation: StatisticsAdditionalInformation;
  email: string;
  firstName: string;
  jobId?: string;
  lastName: string;
  market: string;
  marketSlug?: string;
  numberOfStructures: number;
  numberOfIncludedStructures: number;
  phone: string;
  predictionEventId?: string;
  totalSquareFeet: number;
  totalInitialSquareFeet: number;
  mainRoofTotalSquareFeet: number;
  sessionId: string;
  createdAt: string;
  externalUrl?: string;
  invitationEmails?: string[];
  invitationPhones?: string[];
  originalRepresentative?: User;
  representative?: User;
  status: StatisticsStatusType;
  forceWebLead?: boolean;
  gafQuickMeasurements?: GAFQuickMeasure[];
  eagleviewOrders?: EagleviewOrder[];
}

export type StatisticsListItem = Omit<Statistics, 'additionalInformation'> & {
  parcelExists: boolean;
};

export type Coordinates = {
  latitude: number;
  longitude: number;
};

export interface StatisticsProduct {
  name: string;
  sended?: boolean;
  interestedInFinancing?: boolean;
  priceInfo?: PriceInfo;
  id?: number;
  productName?: string;
  type: string;
  // fields for old records
  price?: number;
  monthlyPrice?: number;
  pricePerSquare?: number;
  addAdditionalCosts?: boolean;
  useInstantQuoteSettings?: boolean;
  customProduct?: { id: number };
}

export interface PotentialContactsStatistics
  extends Pick<
    Statistics,
    | 'address'
    | 'email'
    | 'firstName'
    | 'lastName'
    | 'phone'
    | 'createdAt'
    | 'sessionId'
    | 'id'
    | 'status'
    | 'externalUrl'
  > {
  type: string;
  communicationOptions?: CommunicationOptions | null;
  statistics: StatisticsJoinedData | null;
}

export interface StatisticsStructure {
  initialSquareFeet: number;
  isIncluded: boolean;
  name: string;
  slope: string;
  squareFeet: number;
  deleted?: boolean;
  geoJsonPolygon?: GeoJsonPolygon;
  centroid?: Feature<Point, Properties> | undefined;
  roofComplexity?: string;
  wasteFactor?: StructureWasteFactor;
  customTotalSquareFeet?: number;
  perimeterLinearFeet?: number;
  stories?: number;
}

export interface StructureWasteFactor {
  suggested?: MLWasteFactor;
  custom?: number;
  default?: number;
  usedWFType: WasteFactorTypeEnum;
}

export interface StructureWasteFactorPartialSuggested
  extends Omit<StructureWasteFactor, 'suggested'> {
  suggested?: Partial<MLWasteFactor> | number;
}

export enum StatisticsType {
  ContactsReporting = 'ContactsReporting',
  PotentialContactsReporting = 'PotentialContactsReporting',
  AddressesOnlyReporting = 'AddressesOnlyReporting',
}

export enum DashboardTabKeys {
  ContactsReporting = 'ContactsReporting',
  PotentialContactsReporting = 'PotentialContactsReporting',
  AddressesOnlyReporting = 'AddressesOnlyReporting',
  ImportedContacts = 'ImportedContacts',
}

export interface StatisticsAdditionalInformation {
  additionalCost?: {
    [key: number | string]: AddAdditionalCostsFormValues;
  } | null;
  additionalCostSettings?: QuoteSettingsModel | null;
  addressCenterpoint: Coordinates | null;
  informationState?: InformationState;
  mapScreenshot?: string | null;
  parcel?: GeoJsonPolygon;
  products: StatisticsProduct[];
  structures: StatisticsStructure[];
  squareFeetSettings?: Omit<SquareFeetType, 'value'>;
  priceRangeEnabled: boolean;
  discount?: DiscountSettings;
  thirdPartyReportWasteFactor?: {
    orderId: string | number;
    reportWasteFactors: StructureWasteFactorData[];
  } | null;
  communicationOptions?: CommunicationOptions | null;
}

export type StatisticsProductSettings = Omit<Partial<DBProduct>, 'customProduct'> & {
  customProduct?: Partial<CustomProduct>;
};

export interface AddressOnly {
  id: number;
  address: string;
  createdAt: string;
  externalUrl: string;
  statistics: StatisticsJoinedData | null;
}

export interface Query extends ListQuery {
  filter?: {
    dateFrom?: string;
    dateTo?: string;
    market?: string[];
    status?: string[];
  };
}

export type SelectedRowType = string | number;

export const ProductCode = {
  SF: 'SF',
  MF: 'MF',
  CM: 'CM',
} as const;

export type ProductCodes = typeof ProductCode[keyof typeof ProductCode];

export interface GAFQuickMeasure {
  id: number;
  area: number;
  assets: GAFQuickMeasureAssets;
  bends: number;
  buildings: GAFQuickMeasureBuilding[];
  createdAt: string;
  dripEdge: number;
  eaves: number;
  problemText: string;
  facets: number;
  flash: number;
  hips: number;
  holeArea: number;
  holeCount: number;
  holePerimeter: number;
  instructions: string;
  leakBarrier: number;
  orderNumber: number;
  parapets: number;
  pitch: string;
  pitchToArea: { [key: number]: number };
  productCode: string;
  rakes: number;
  ridgeCap: number;
  ridges: number;
  starter: number;
  statisticsId: number;
  status: MeasureOrderStatus;
  step: number;
  structuresType: MeasurementsStructures;
  subscriberOrderNumber: string;
  updatedAt: string;
  valleys: number;
}

export interface GAFQuickMeasureAssets {
  report: string;
  cover: string;
  diagram: string;
  acculynx: string;
  homeownerReport: string;
  report3D: string;
  verticalImage: string;
  northImage: string;
  eastImage: string;
  southImage: string;
  westImage: string;
}

export interface GAFQuickMeasureBuilding {
  area: number;
  dripEdge: number;
  eaves: number;
  facets: number;
  flash: number;
  hips: number;
  holeArea: number;
  holeCount: number;
  holePerimeter: number;
  leakBarrier: number;
  pitch: number;
  pitchToArea: {
    [key: number]: number;
  };
  rakes: number;
  ridgeCap: number;
  ridges: number;
  starter: number;
  step: number;
  suggestedWaste: number;
  valleys: number;
}

export interface GAFQuickMeasureSiteStatus {
  siteAverageResponseTime: number;
  siteStatus: 'Open' | 'Close';
}

export enum MeasureOrderStatus {
  inProgress = 'InProgress',
  declined = 'Declined',
  pending = 'Pending',
  completed = 'Completed',
  failed = 'Failed',
}

export type MeasurementsOrderBody = {
  statisticsId: number;
} & Record<string, string | number | Coordinates>;

export enum MeasurementsStructures {
  All = 'All',
  MainRoofOnly = 'MainRoofOnly',
  MainRoofPlus = 'MainRoofPlus',
  CommercialComplex = 'CommercialComplex',
  Other = 'Other',
}

type EagleviewReportType = {
  statusId: number;
  subStatusId: number;
  totalCost: number;
  paymentType: string;
  measurementByStructure: {
    buildingName: string;
    area: string;
    primaryPitch: string;
    lengthRidge: string;
    lengthValley: string;
    lengthEave: string;
    lengthRake: string;
    lengthHip: string;
    wallsSidingArea: string;
    wallsMasonryArea: string;
    areaValue: number;
    pitchValue: number;
    totalRoofFacets: string;
    pitchTable: {
      pitch: string;
      roofArea: string;
      percentageRoofArea: string;
    };
  }[];
  primaryProductDisplayName: string;
  typeOfStructure: number;
  totalMeasurements: {
    buildingName: string;
    area: string;
    primaryPitch: string;
    lengthRidge: string;
    lengthValley: string;
    lengthEave: string;
    lengthRake: string;
    lengthHip: string;
    wallsSidingArea: string;
    wallsMasonryArea: string;
    areaValue: number;
    pitchValue: number;
    totalRoofFacets: string;
    pitchTable: {
      pitch: string;
      roofArea: string;
      percentageRoofArea: string;
    };
  };
  pitch: string;
  reportId: number;
  street: string;
  buildingId: string;
  city: string;
  state: string;
  zip: string;
  latitude: number;
  longitude: number;
  claimNumber: string;
  claimInfo: string;
  batchId: string;
  catId: string;
  datePlaced: string;
  dateCompleted: string;
  PONumber: string;
  comments: string;
  referenceId: string;
  insuredName: string;
  measurementRequestType: number;
  reportDownloadLink: string;
  eligibleForUpgrade: boolean;
  status: string;
  area: string;
  primaryPitch: string;
  lengthRidge: string;
  lengthValley: string;
  lengthEave: string;
  lengthRake: string;
  productPrimaryId: number;
  productPrimary: string;
  productDeliveryId: number;
  productDelivery: string;
  lengthHip: string;
  profileId: number;
  userName: string;
  totalRoofFacets: string;
  pitchTable: {
    pitch: string;
    roofArea: string;
    percentageRoofArea: string;
  }[];
  structureWasteTables: {
    complexity: string;
    structureWasteTable: string[][];
  }[];
  // structureWasteTable example
  //
  // [(['Waste %', '0%', '12%', '14%', '16%'],
  // ['Area (Sq ft)', '3544', '3970', '4041', '4112'],
  // ['Squares *', '35.66', '40.00', '40.66', '41.33'],
  // [null, 'Measured', null, 'Suggested', null])];
};

export type EagleviewOrder = {
  id: number;
  createdAt: string | Date;
  updatedAt: string;
  type:
    | MeasurementsReportType.EagleViewBidPerfect
    | MeasurementsReportType.EagleViewPremiumCommercial
    | MeasurementsReportType.EagleViewPremiumResidential;
  eagleviewUserId: string;
  reportId?: number;
  status: MeasureOrderStatus;
  deliveryType: DeliveryTimeType;
  structuresType: MeasurementsStructures;
  report?: EagleviewReportType;
  structures?: EagleviewReportType['measurementByStructure'];
  wasteFactorTable?: EagleviewReportType['structureWasteTables'];
};

export type UpsertEagleviewOrderBodyType = {
  id?: number;
  accountId?: number;
  address: string;
  city: string;
  state: string;
  zip: string;
  reportType: MeasurementsReportType;
  centerpoint: Coordinates;
  statisticsId: number;
  structuresType: MeasurementsStructures;
  deliveryType: DeliveryTimeType;
};

export enum LeadDetailsTabKeys {
  Details = 'details',
  Measurements = 'measurements',
  GAFQuickMeasure = 'gafQuickMeasure',
  EagleviewBidPerfect = 'eagleviewBidPerfect',
  EagleviewPremiumCommercial = 'eagleviewPremiumCommercial',
  EagleviewPremiumResidential = 'eagleviewPremiumResidential',
  Order = 'Order',
  Notes = 'Notes',
}

export type EagleviewReportWithOrderData = EagleviewReportType & EagleviewOrder;
export type ReportType = GAFQuickMeasure | EagleviewReportWithOrderData;

export const assigneeStatusFilterValues = {
  all: 'all',
  reassigned: 'reassigned',
  notReassigned: 'not_reassigned',
} as const;

export const leadSourceFilterValues = {
  repLeads: 'rep_leads',
  webLeads: 'web_leads',
} as const;

export type FiltersOptionsResponse = {
  markets: string[];
  representatives: {
    id: number;
    firstName: string;
    lastName: string;
  }[];
  leadStatuses: string[];
  totals: {
    assigneeStatus: {
      [assigneeStatusFilterValues.all]: number;
      [assigneeStatusFilterValues.reassigned]: number;
      [assigneeStatusFilterValues.notReassigned]: number;
    };
    markets: Record<string, number>;
    representatives: Record<number | string, number>;
    leadStatuses: Record<string, number>;
    leadSources: Record<string, number>;
  };
};

export type SetFormInitialValuesParams = {
  initialLeadSourceOptions: OptionType[];
  initialLeadStatusFilterOptions: OptionType[];
  initialMarketOptions: OptionType[];
  initialRepresentativeOptions: OptionType[];
};

export interface FormattedAdditionalInformation {
  discounts?: DiscountModel[];
  translations?: Record<string, string>;
}

export interface InformationState {
  productsHaveBeenChanged: boolean;
}

export interface ReassignStatisticsBody {
  statisticsIds: number[];
  newOwnerId: number | null;
}

export type PartialStatistics = Partial<Omit<Statistics, 'additionalInformation'>> & {
  additionalInformation?: Partial<StatisticsAdditionalInformation>;
};

export type QuickQuoteRequestBody = Omit<Statistics, 'createdAt' | 'forceWebLead' | 'status'> & {
  formattedAdditionalInformation?: FormattedAdditionalInformation;
  isTotalSquareFeetModified?: boolean;
};

export type QuickQuoteEncryptedRequestBody = Omit<
  Statistics,
  'createdAt' | 'forceWebLead' | 'status' | 'additionalInformation'
> & {
  formattedAdditionalInformation?: FormattedAdditionalInformation;
  isCustomSquareFeetApplied?: boolean;
  additionalInformation: string;
  additionalCostOptions?: string;
};

export type QuickQuoteRequestBodySourceRecord = Pick<
  QuoteCreatingData,
  'recordId' | 'recordSource'
>;

export enum BuildingImageTagType {
  North = 1,
  South,
  East,
  West,
}

export const QuoteProductsDetailsTabs = {
  ProductDetails: 'ProductDetails',
  QuotesSent: 'QuotesSent',
} as const;
export type QuoteProductsDetailsTabKeys =
  typeof QuoteProductsDetailsTabs[keyof typeof QuoteProductsDetailsTabs];

export interface QuoteProduct {
  id: number;
  name: string;
  priceInfo?: PriceInfo;
}

export interface Quote {
  id: number;
  sentFrom: string;
  sentTo: string;
  createdAt: string;
  measurementsReportType?: MeasurementsReportType;
  products: QuoteProduct[];
  totalSquareFeet: number;
}

export interface CommunicationOptions {
  contactOptIn?: boolean;
  smsOptIn?: boolean;
}

export interface ImportedContact {
  id: number;
  address: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  source: string;
  status: StatisticsStatusType;
  statistics: StatisticsJoinedData | null;
  createdAt: string;
}

type StatisticsJoinedData = Pick<Statistics, 'id' | 'firstName' | 'lastName'>;

export const StatisticsStatus = {
  cold: 'Cold',
  deleted: 'Deleted',
  lost: 'Lost',
  new: 'New',
  open: 'Open',
  sold: 'Sold',
} as const;

export type StatisticsStatusType = typeof StatisticsStatus[keyof typeof StatisticsStatus];

export type ImportedContactsFiltersOptions = {
  sources: string[];
  totals: {
    statuses: Record<string, number>;
    sources: Record<string, number>;
  };
};

export const ImportedContactsFiltersFields = {
  status: 'status',
  source: 'source',
  createDate: 'createDate',
} as const;

export interface ImportedContactsFilters {
  [ImportedContactsFiltersFields.status]: string[];
  [ImportedContactsFiltersFields.source]: string[];
  [ImportedContactsFiltersFields.createDate]: { dateFrom?: string; dateTo?: string };
}

export interface ImportedContactsQuery extends ListQuery {
  filter?: Partial<ImportedContactsFilters>;
}

export const IMPORT_CONTACTS_FORM_FIELDS = {
  fileName: 'fileName',
  file: 'file',
} as const;

export interface ImportContactsForm {
  [IMPORT_CONTACTS_FORM_FIELDS.fileName]: string;
  [IMPORT_CONTACTS_FORM_FIELDS.file]: FormDragAndDropValue;
}

export interface Note {
  id: number;
  createdAt: string;
  note: string;
  commenter: Pick<User, 'id' | 'firstName' | 'lastName'>;
}

export interface NoteRequestBody {
  id?: Note['id'];
  note: Note['note'];
}
