import api from 'services/api';
import {
  CatalogListQuery,
  Line,
  RequestProductsBodyType,
  CustomProduct,
  RequestProductDifferenceType,
} from './types';
import { QuickQuoteEncryptedRequestBody } from 'modules/dashboard/types';

export const getProductsApi = ({ filter = {}, pagination, search = '' }: CatalogListQuery) =>
  api.get('products', {
    params: {
      filter: JSON.stringify(filter),
      pagination: JSON.stringify(pagination),
      sorting: 'recommended',
      search,
      codes: JSON.stringify([]),
    },
  });

export const getProductsCountApi = ({ filter = {}, search = '' }: CatalogListQuery) =>
  api.get('products/count', {
    params: {
      filter: JSON.stringify(filter),
      pagination: {},
      search,
      codes: JSON.stringify([]),
    },
  });

export const saveSelectedProductsAPI = ({
  userId,
  marketSlug,
  products,
}: {
  userId: number;
  marketSlug: string;
  products: Line[];
}) => api.post(`user/${userId}/products/${marketSlug}`, { products });

export const publicRequestWidgetProductEmailAPI = async (
  wildcard: string,
  productCode: string,
  productId: number,
  body: QuickQuoteEncryptedRequestBody,
  queryParams?: Partial<{
    [key: string]: string | number | boolean;
  }>,
) =>
  api.post(`/widget/${wildcard}/product/${productId}/${productCode}/email`, body, {
    params: queryParams,
  });

export const requestProductsEmailAPI = (userId: number, wildcard: string, body: FormData) =>
  api.post(`/user/${userId}/widget/${wildcard}/products/email`, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const requestProductsAPI = (userId: number, body: RequestProductsBodyType) =>
  api.post(`user/${userId}/products/request`, body);

export const getCompanyLabelsAPI = (userId: number) => api.get(`user/${userId}/labels`);

export const addCompanyLabelAPI = (userId: number, label: string) =>
  api.post(`user/${userId}/label`, { label });

export const updateCompanyLabelAPI = (userId: number, labelId: number, label: string) =>
  api.patch(`user/${userId}/label/${labelId}`, { label });

export const deleteCompanyLabelAPI = (userId: number, labelId: number) =>
  api.delete(`user/${userId}/label/${labelId}`);

export const upsertCustomProductAPI = (userId: number, body: CustomProduct) =>
  api.put(`user/${userId}/custom-product`, body);

export const deleteCustomProductAPI = (userId: number, id: number) =>
  api.delete(`user/${userId}/custom-product/${id}`);

export const getCustomProductsAPI = (userId: number, { pagination }: CatalogListQuery) =>
  api.get(`user/${userId}/custom-products`, {
    params: {
      pagination: JSON.stringify(pagination),
    },
  });

export const getCustomProductsCountAPI = (userId: number) =>
  api.get(`user/${userId}/custom-products/count`, {
    params: {},
  });

export const updateWidgetProductAPI = (
  userId: number,
  id: number,
  product: Partial<RequestProductDifferenceType>,
  marketSlug: string,
) => api.post(`user/${userId}/widget/product/${id}/${marketSlug}`, product);

export const resetWidgetProductAPI = (userId: number, id: number, marketSlug: string) =>
  api.get(`user/${userId}/widget/reset-product/${id}/${marketSlug}`);

export const applyProductInfoAPI = (
  userId: number,
  body: { productIds: number[]; productInfo: Partial<RequestProductDifferenceType> },
) => api.put(`user/${userId}/apply-product-info`, body);

export const getUndeletableProductMarketsAPI = (userId: number, id: number) =>
  api.get(`user/${userId}/undeletable-product-markets/${id}`);

export const updateProductsForMarketAPI = (
  userId: number,
  marketSlug: string,
  body: Partial<RequestProductDifferenceType>,
) => api.put(`user/${userId}/products/${marketSlug}`, body);

export const getProductsForApplyAPI = (userId: number, marketSlugs: string[]) =>
  api.post(`user/${userId}/products-for-apply`, { marketSlugs });

export const getProductsCombinedByName = (userId: number, marketSlugs: string[]) =>
  api.post(`user/${userId}/products-combined-by-name`, { marketSlugs });

export const getIsLabelUsedAPI = (userId: number, labelId: number, params) =>
  api.get(`user/${userId}/label/${labelId}/is-used`, { params });
