import { ActionType } from 'types';
import * as constants from './constants';
import { RepQuotesState } from './types';

const initialState: RepQuotesState = {
  userWidgetSettings: { saveLeadInfo: false, commentTitle: '', commentText: '' },
  selectedColoredLines: {},
  leadSessionId: '',
  leadSessionIdChangedAt: 0,
  quoteCreatingData: null,
};

const repQuotesReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case constants.SET_USER_WIDGET_SETTINGS:
      return { ...state, userWidgetSettings: action.userWidgetSettings };
    case constants.SET_SELECTED_COLOR_LINE: {
      return {
        ...state,
        selectedColoredLines: { ...state.selectedColoredLines, ...action.coloredLine },
      };
    }
    case constants.SET_LEAD_SESSION_ID: {
      return { ...state, leadSessionId: action.leadSessionId, leadSessionIdChangedAt: Date.now() };
    }
    case constants.SET_QUOTE_CREATING_DATA: {
      return { ...state, quoteCreatingData: action.quoteCreatingData };
    }
    default:
      return state;
  }
};

export default repQuotesReducer;
